<template>
  <!-- 
  issus:
   -->
  <div class="relative">

    <!-- Start notification -->
    <successNotification v-if="this.showSuccessNotification == true"></successNotification>
    <failNotification v-if="this.showFailNotification == true"></failNotification>
    <errorNotification v-if="this.showNotification == true" :NotificationMsg="NotificationMsg"></errorNotification>
    <!-- End notification -->

    <div class="flex relative">
      <menuComponent class="w-1/6"></menuComponent>
      <div class="left-0 absolute w-5/6  px-12">
        <headerComponent></headerComponent>
        <div class="mt-20 mb-10">
          <h3 class="text-3xl font-bold mb-2">إضافة قاعة جديدة</h3>

          <div class="border rounded-lg">
            <div class=" w-fit mx-auto my-16 space-y-10">

              <!-- FIRST ROW OF THE FORM -->
              <div class="flex justify-start gap-4">
                <div class=" space-y-2">
                  <label class="text-secondary" for="hallName">اسم القاعة</label>
                  <div>
                    <input class="inputFild" type="text" name="hallName" id="hallName" placeholder="أدخل اسم القاعة"
                      v-model="hallName" v-validate="'required|hallName'">
                  </div>
                  <p class="error">{{ errors.first('hallName') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="hallName == '' && errors.first('hallName') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="pplNumber">عدد الأشخاص المتاح</label>
                  <div>
                    <input class="inputFild" type="number" min="0" name="pplNumber" id="pplNumber"
                      placeholder="ادخل الحد الأعلى لعدد الأشخاص" v-model="pplNumber" v-validate="'required|numeric'">
                  </div>
                  <p class="error">{{ errors.first('pplNumber') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="pplNumber == '' && errors.first('pplNumber') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF FIRST ROW OF THE FORM -->

              <!-- SECOND ROW OF THE FORM -->
              <div class="flex justify-start gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="available_hours">عدد الساعات المتاح</label>
                  <div>
                    <input @input="validateAvailableHours" class="inputFild" type="number" min="0" max="4"
                      name="available_hours" id="available_hours" placeholder="الحد الأعلى لعدد الساعات هو 4 ساعات"
                      v-model="available_hours" v-validate="'required|numeric|max_value:4'">
                  </div>
                  <p class="error">{{ errors.first('available_hours') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="available_hours == '' && errors.first('available_hours') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="startTime">وقت بداية العمل</label>
                  <div class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-[41.6px]"
                      name="startTime" id="startTime" v-model="startTime" v-validate="'required'">
                      <option v-for="time in startTimeOptions" :key="time.timeValue" :value="time.time"
                        style="direction: ltr; text-align: right;">{{ time.time }}
                      </option>
                    </select>
                  </div>
                  <p class="error">{{ errors.first('startTime') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="startTime == '' && errors.first('startTime') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

              </div>
              <!-- END OF SECOND ROW OF THE FORM -->

              <!-- THIRD ROW OF THE FORM -->
              <div class="flex justify-start gap-4">
                <div class=" space-y-2">
                  <label class="text-secondary" for="endTime">وقت نهاية العمل</label>
                  <div class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-[41.6px]"
                      name="endTime" id="endTime" v-model="endTime" v-validate="'required'">
                      <option v-for="time in endTimeOptions" :key="time.timeValue" :value="time.time"
                        style="direction: ltr; text-align: right;">{{ time.time }}
                      </option>
                    </select>
                  </div>
                  <p class="error">{{ errors.first('endTime') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="endTime == '' && errors.first('endTime') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="hallStatus">حالة القاعة</label>
                  <div class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-[41.6px]"
                      name="hallStatus" id="hallStatus" v-model="hallStatus">
                      <option value="فعال">فعال</option>
                      <option value="موقوف">موقوف</option>
                      <option value="معلق">معلق</option>
                    </select>
                  </div>
                  <div v-if="showValidationError">
                    <p v-if="hallStatus == ''" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF THIRD ROW OF THE FORM -->

              <div v-if="isLoading" class="flex justify-center items-center">
                <div class="text-center">
                    <div role="status" class="flex gap-2">
                        <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class=" text-gray-500 font-semibold">جاري الإرسال</span>
                    </div>
                </div>
              </div>

              <!-- Start Button -->
              <div class=" text-center">
                <button @click="submitData"
                  class="btn_hover rounded-lg bg-gradient-to-b from-[#ED8F37] to-[#ED8F37A6] px-16 py-2 text-yellow-50 text-lg">إضافة</button>
              </div>
              <!-- End Button -->
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import menuComponent from '@/components/menu-booking.vue'
import headerComponent from '@/components/headerSection.vue'
import successNotification from '@/components/Notification/successNotification.vue'
import failNotification from '@/components/Notification/failNotification.vue'
import errorNotification from '@/components/Notification/errorNotification.vue'
import hallTimeFunction from '@/mixins/hallTimeFunction.js'
import { supabase } from '../../../supabase';

export default {
  components: {
    menuComponent,
    headerComponent,
    successNotification,
    failNotification,
    errorNotification
  },
  data() {
    return {
      identificationNumber: '',

      hallName: '',
      pplNumber: '',
      available_hours: '',
      startTime: '',
      endTime: '',
      hallStatus: '',
      // OPTION OF TIME VAR SAVER
      times: [
        { time: '8:00 AM', timeValue: '8' },
        { time: '9:00 AM', timeValue: '9' },
        { time: '10:00 AM', timeValue: '10' },
        { time: '11:00 AM', timeValue: '11' },
        { time: '12:00 PM', timeValue: '12' },
        { time: '1:00 PM', timeValue: '13' },
        { time: '2:00 PM', timeValue: '14' },
        { time: '3:00 PM', timeValue: '15' },
        { time: '4:00 PM', timeValue: '16' },
        { time: '5:00 PM', timeValue: '17' },
        { time: '6:00 PM', timeValue: '18' },
        { time: '7:00 PM', timeValue: '19' },
        { time: '8:00 PM', timeValue: '20' },
      ],
      showSuccessNotification: false,
      showFailNotification: false,
      showValidationError: false,
      showNotification: false,
      NotificationMsg: '',
      isLoading: false // icon of loading 
    }
  },
  methods: {
    // Function to submit hall data
    async submitData() {
  // Indicate loading state
  this.isLoading = true;

  // Check if all fields are filled and there are no validation errors
  if (this.hallName && this.pplNumber && this.available_hours && this.startTime && this.endTime && this.hallStatus &&
    !this.errors.has('hallName')) {

    // Call the asynchronous function checkIfDuplicates to determine if there are any duplicate entries in the database.
    const isDuplicates = await this.checkIfDuplicates();
    // If duplicates are found (isDuplicates is true), return from the function without further processing.
    if (isDuplicates == true) {
      this.isLoading = false; // Stop loading if duplicates are found
      return;
    }

    // Proceed with insertion if no entry with the same hall name exists
    try {
      const { data } = await supabase
        .from('Hall')
        .insert([{ hallName: this.hallName, pplNumber: this.pplNumber, available_hours: this.available_hours, startTime: this.startTime, endTime: this.endTime, hallStatus: this.hallStatus, creatingDate: new Date().toISOString().split('T')[0] }])
        .select()

      // Show the success notification after successful insertion
      this.showSuccessNotification = true;

      // Hide the notification after 3 seconds, set identificationNumber, send notification, and redirect
      setTimeout(() => {
        this.showSuccessNotification = false;
        this.identificationNumber = data[0].identificationNumber;
        this.insertNotification();
        this.$router.push('/hallManagementPage'); // Redirect after hiding the notification
        this.isLoading = false; // Stop loading after successful completion
      }, 1700);

    } catch (error) {
      console.error('Error inserting data:', error.message);

      // Show the fail notification if insertion fails
      this.showFailNotification = true;

      // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
      setTimeout(() => {
        this.showFailNotification = false;
        this.showValidationError = true;
        this.isLoading = false; // Stop loading after error
      }, 1700);
    }

  } else {
    // Show the fail notification if any field is empty or there are validation errors
    this.showFailNotification = true;

    // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
    setTimeout(() => {
      this.showFailNotification = false;
      this.showValidationError = true;
      this.isLoading = false; // Stop loading after validation error
    }, 1700);
    return; // Stop the function execution here
  }
},

    // Function to check for duplicate entries in the database
    async checkIfDuplicates() {

      // Check if hall name already exists
      const { data: existingHallNameData, error: hallNameError } = await supabase
        .from('Hall')
        .select('*')
        .eq('hallName', this.hallName);
      if (hallNameError) {
        console.error('Error fetching hallName data:', hallNameError.message);
        return true; // Stop the function execution here
      }

      // If there is already an entry with the same hallName, show alert
      if (existingHallNameData && existingHallNameData.length > 0) {
        this.NotificationMsg = 'اسم القاعة المدخل موجود مسبقا';
        this.showNotification = true;
        setTimeout(() => {
          this.showNotification = false;
        }, 1700);
        return true; // Stop the function execution here
      }
    },
    // Function to asynchronously insert a notification into the database
    async insertNotification() {

      // Prepare the notification object
      const notification = {
        by: sessionStorage.getItem('userName'), // Get the sender's username from sessionStorage
        notificationType: 'newHall', // Set the notification type to 'newHall'
        identificationNumber: this.identificationNumber, // Include the identification number associated with the hall
        readed_by: [], // Initialize an empty array for users who have read the notification
      };

      // Send the notification to the Supabase notification table
      await supabase
        .from('notification')
        .insert([notification])
        .then(result => {
          // Check if there is an error in the result
          if (result.error) {
            console.error('Failed to send notification:', result.error); // Log the error if there is one
          }
        }).catch(error => {
          console.error('Error sending notification:', error); // Log any error that occurred during the process
        });
        
    },
  },
  mixins: [hallTimeFunction],
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped></style>