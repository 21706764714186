<template>
  <!-- 
  issus:
   -->
  <div>

    <!-- Start notification -->
    <successNotification v-if="this.showSuccessNotification == true"></successNotification>
    <failNotification v-if="this.showFailNotification == true"></failNotification>
    <!-- End notification -->

    <div class="flex relative">
      <menuComponent class="w-1/6"></menuComponent>
      <div class="left-0 absolute w-5/6  px-12">
        <headerComponent></headerComponent>
        <div class=" mt-20 mb-10">
          <h3 class="text-3xl font-bold mb-2">تعديل بيانات الاجتماع</h3>

          <div class="border rounded-lg">

            <div v-if="entityType === 'خارجي'" class=" w-fit mx-auto my-16 space-y-10">

              <!-- FIRST ROW OF THE FORM -->
              <div class="flex justify-start gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="entityName">اسم الجهة</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild" name="entityName" id="entityName" placeholder="ادخل اسم الجهة"
                      v-model="entityName" v-validate="'required|alpha_spaces'">
                  </div>
                  <p class="error">{{ errors.first('entityName') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="entityName == '' && errors.first('entityName') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="reservationHolderName">اسم صاحب الحجز</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild" name="reservationHolderName" id="reservationHolderName"
                      placeholder="اسم صاحب الحجز" v-model="reservationHolderName" v-validate="'required|alpha_spaces'">
                  </div>
                  <p class="error">{{ errors.first('reservationHolderName') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="entityActivity == '' && errors.first('reservationHolderName') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

              </div>
              <!-- END OF FIRST ROW OF THE FORM -->

              <!-- SECOND ROW OF THE FORM -->
              <div class="flex justify-start gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="entityActivity">نشاط الجهة</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild" name="entityActivity" id="entityActivity"
                      placeholder="نشاط الجهة" v-model="entityActivity" v-validate="'required|alpha_spaces'">
                  </div>
                  <p class="error">{{ errors.first('entityActivity') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="entityActivity == '' && errors.first('entityActivity') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="phoneNumber">رقم الجوال</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild text-right" dir="ltr" name="phoneNumber" id="phoneNumber"
                      placeholder="" v-model="phoneNumber" v-validate="'required|numeric|min:10|max:10'">
                  </div>
                  <p class="error">{{ errors.first('phoneNumber') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="phoneNumber == '' && errors.first('phoneNumber') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

              </div>
              <!-- END OF SECOND ROW OF THE FORM -->

              <!-- THIRD ROW OF THE FORM -->
              <div class="flex justify-center gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="meetingRoom">اختر القاعة</label>
                  <div class="w-[360px]">
                    <select @change="available_hours(); timeRange()"
                      class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10" name="meetingRoom"
                      id="meetingRoom" v-model="meetingRoom">
                      <option v-for="(room, index) in meetingRooms" :key="index" :value="room">{{ room }}</option>
                    </select>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="meetingDate">تاريخ الاجتماع</label>
                  <div>
                    <input @change="checkUnavailableDates" class="inputFild" type="date" name="meetingDate"
                      id="meetingDate" placeholder="حدد وقت نهاية العمل كل يوم" v-model="meetingDate" :min="minDate"
                      v-validate="'required'">
                  </div>
                  <p class="error">{{ errors.first('meetingDate') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="meetingDate == '' && errors.first('meetingDate') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

              </div>
              <!-- END OF THIRD ROW OF THE FORM -->

              <!-- THIRD ROW OF THE FORM -->
              <div class="flex justify-center gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="meeting_duration">اختر مدة الاجتماع</label>
                  <div class="w-[360px]">
                    <select @change="deleteStartTime"
                      class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10"
                      name="meeting_duration" id="meeting_duration" v-model="meeting_duration" v-validate="'required'">
                      <option v-for="(d) in duration" :key="d.value" :value="d.value">{{ d.time }}
                      </option>
                    </select>
                  </div>
                  <p class="error">{{ errors.first('meeting_duration') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="meeting_duration == '' && errors.first('meeting_duration') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="startTime">اختر وقت بداية الاجتماع</label>
                  <div class="w-[360px]">
                    <select @focus="filteredStartTimes" @change="filteredEndTimes"
                      class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10" name="startTime"
                      id="startTime" v-model="startTime" v-validate="'required'">
                      <option v-for="time in filteredTimes" :key="time.value" :value="time"
                        style="direction: ltr; text-align: right;">{{ time }}
                      </option>
                    </select>
                  </div>
                  <p class="error">{{ errors.first('startTime') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="startTime == '' && errors.first('startTime') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

              </div>
              <!-- END OF THIRD ROW OF THE FORM -->

              <!-- 5 ROW OF THE FORM -->
              <div class="flex justify-center gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="meetingStatus">حالة الاجتماع</label>
                  <div class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-[41.6px]"
                      name="meetingStatus" id="meetingStatus" v-model="meetingStatus">
                      <option value="محجوز">محجوز</option>
                      <option value="ملغي">ملغي</option>
                      <option value="معلق">معلق</option>
                    </select>
                  </div>
                </div>

              </div>
              <!-- END OF 5 ROW OF THE FORM -->

              <!-- 6 ROW OF THE FORM -->
              <div class="flex justify-center gap-4">

                <div class=" space-y-2">
                  <p v-if="this.startTime && this.endTime">بداية وقت الاجتماع الساعة <span die="ltr">{{ this.startTime
                      }}</span> وينتهي
                    الساعة <span dir="ltr">{{ this.endTime }}</span></p>
                </div>

              </div>
              <!-- END OF 6 ROW OF THE FORM -->

              <!-- Start Button -->
              <div class=" text-center">
                <button @click="submitData"
                  class="btn_hover rounded-lg bg-gradient-to-b from-[#ED8F37] to-[#ED8F37A6] px-16 py-2 text-yellow-50 text-lg">تعديل</button>
              </div>
              <!-- End Button -->

            </div>

            <div v-if="entityType === 'داخلي'" class=" w-fit mx-auto my-16 space-y-10">

              <!-- FIRST ROW OF THE FORM -->
              <div class="flex justify-start gap-4">
                <div class=" space-y-2">
                  <label class="text-secondary" for="entityName">اختر الجهة</label>
                  <div class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10"
                      name="entityName" id="entityName" v-model="entityName">
                      <option v-for="(entity, index) in entitys" :key="index" :value="entity">{{ entity }}</option>
                    </select>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="meetingRoom">اختر القاعة</label>
                  <div class="w-[360px]">
                    <select @change="available_hours(); timeRange()"
                      class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10" name="meetingRoom"
                      id="meetingRoom" v-model="meetingRoom">
                      <option v-for="(room, index) in meetingRooms" :key="index" :value="room">{{ room }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- END OF FIRST ROW OF THE FORM -->

              <!-- SECOND ROW OF THE FORM -->
              <div class="flex justify-start gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="meetingDate">تاريخ الاجتماع</label>
                  <div>
                    <input @change="checkUnavailableDates" class="inputFild" type="date" name="meetingDate"
                      id="meetingDate" placeholder="حدد وقت نهاية العمل كل يوم" v-model="meetingDate" :min="minDate"
                      v-validate="'required'">
                  </div>
                  <p class="error">{{ errors.first('meetingDate') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="meetingDate == '' && errors.first('meetingDate') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="meeting_duration">اختر مدة الاجتماع</label>
                  <div class="w-[360px]">
                    <select @change="deleteStartTime"
                      class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10"
                      name="meeting_duration" id="meeting_duration" v-model="meeting_duration" v-validate="'required'">
                      <option v-for="(d) in duration" :key="d.value" :value="d.value">{{ d.time }}
                      </option>
                    </select>
                  </div>
                  <p class="error">{{ errors.first('meeting_duration') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="meeting_duration == '' && errors.first('meeting_duration') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

              </div>
              <!-- END OF SECOND ROW OF THE FORM -->

              <!-- THIRD ROW OF THE FORM -->
              <div class="flex justify-center gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="startTime">اختر وقت بداية الاجتماع</label>
                  <div class="w-[360px]">
                    <select @focus="filteredStartTimes" @change="filteredEndTimes"
                      class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10" name="startTime"
                      id="startTime" v-model="startTime" v-validate="'required'">
                      <option v-for="time in filteredTimes" :key="time.value" :value="time"
                        style="direction: ltr; text-align: right;">{{ time }}
                      </option>
                    </select>
                  </div>
                  <p class="error">{{ errors.first('startTime') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="startTime == '' && errors.first('startTime') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="meetingStatus">حالة الاجتماع</label>
                  <div class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-[41.6px]"
                      name="meetingStatus" id="meetingStatus" v-model="meetingStatus">
                      <option value="محجوز">محجوز</option>
                      <option value="ملغي">ملغي</option>
                      <option value="معلق">معلق</option>
                      <option value="مكتمل">مكتمل</option>
                    </select>
                  </div>
                </div>

              </div>
              <!-- END OF THIRD ROW OF THE FORM -->

              <!-- Fourth ROW OF THE FORM -->
              <div class="flex justify-center gap-4">

<div class=" space-y-2">
  <label class="text-secondary" for="meetingPpl">اعضاء الاجتماع:</label>
  <div class="w-[360px]">
    <textarea type="text" class="inputFild" name="meetingPpl" id="meetingPpl" placeholder="اسماء أعضاء الاجتماع"
      v-model="meetingPpl">
    </textarea>
  </div>
</div>

</div>
<!-- END OF Fourth ROW OF THE FORM -->

              <!-- 4 ROW OF THE FORM -->
              <div class="flex justify-center gap-4">

                <div class=" space-y-2">
                  <p v-if="this.startTime && this.endTime">بداية وقت الاجتماع الساعة <span die="ltr">{{ this.startTime
                      }}</span> وينتهي
                    الساعة <span dir="ltr">{{ this.endTime }}</span></p>
                </div>

              </div>
              <!-- END OF 4 ROW OF THE FORM -->

              

              <div v-if="isLoading" class="flex justify-center items-center">
                <div class="text-center">
                    <div role="status" class="flex gap-2">
                        <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class=" text-gray-500 font-semibold">جاري الإرسال</span>
                    </div>
                </div>
              </div>

              <!-- Start Button -->
              <div class=" text-center">
                <button @click="submitData"
                  class="btn_hover rounded-lg bg-gradient-to-b from-[#ED8F37] to-[#ED8F37A6] px-16 py-2 text-yellow-50 text-lg">تعديل</button>
              </div>
              <!-- End Button -->
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</template>


<script>
import menuComponent from '@/components/menu-booking.vue'
import headerComponent from '@/components/headerSection.vue'
import successNotification from '@/components/Notification/successNotification.vue'
import failNotification from '@/components/Notification/failNotification.vue'
import filter from '@/mixins/filter.js'
import { supabase } from '../../../supabase';
import moment from "moment"; 

export default {
  components: {
    menuComponent,
    headerComponent,
    successNotification,
    failNotification
  },
  data() {
    return {
      identificationNumber: '',
      entityType: '',

      reservationHolderName: '',
      entityActivity: '',
      phoneNumber: '',

      entitys: [],
      entityName: '',

      meetingRooms: [],
      meetingRoom: '',

      timeArray: [],
      filteredTimes: [],

      meetingDate: '',
      unavailableDates: [],

      duration: [],
      meeting_duration: '',

      startTime: '',
      endTime: '',

      remainStartTime: '',

      meetingStatus: '',

      showSuccessNotification: false,
      showFailNotification: false,
      showValidationError: false,

      routeMeetingData: this.$route.params.meetingData,

      isLoading: false, // icon of loading 
      oldMeetingStatus: "", // Added to track the old status
      meetingPpl: ''
    }
  },
  methods: {
    // Function to submit meeting data
    async submitData() {
  // Check if all required fields are filled
  if (this.entityName && this.meetingDate && this.meetingRoom && this.meeting_duration && this.startTime && this.endTime && this.meetingStatus) {

    // Set isLoading to true before starting the data submission process
    this.isLoading = true;


    try {


      // Check if the status has changed to 'مكتمل' from 'جاري الاجتماع'
      if (this.meetingStatus === "مكتمل" && this.oldMeetingStatus === "الاجتماع جاري") {
        // Update end time to the current time (e.g., 9:30 AM)
        this.endTime = moment().format("h:mm A");
      }


      if (this.entityType == 'داخلي') {
        // Update the Meeting record in the 'Meeting' table for internal entities
        await supabase
          .from('Meeting')
          .update({ 'entityName': this.entityName, 'reservationHolderName': this.reservationHolderName, 'meetingRoom': this.meetingRoom, 'meetingDate': this.meetingDate, 'meeting_duration': this.meeting_duration, 'startTime': this.startTime, 'endTime': this.endTime, 'meetingStatus': this.meetingStatus, 'meetingPpl': this.meetingPpl })
          .eq('identificationNumber', this.identificationNumber)
      } else {
        // Update the Meeting record in the 'Meeting' table for external entities
        await supabase
          .from('Meeting')
          .update({ 'entityName': this.entityName, 'reservationHolderName': this.reservationHolderName, 'meetingRoom': this.meetingRoom, 'meetingDate': this.meetingDate, 'meeting_duration': this.meeting_duration, 'startTime': this.startTime, 'endTime': this.endTime, 'meetingStatus': this.meetingStatus, 'entityActivity': this.entityActivity, 'phoneNumber': this.phoneNumber })
          .eq('identificationNumber', this.identificationNumber)
      }

      // Show the success notification after insertion is successful
      this.showSuccessNotification = true;

      // Hide the notification after 3 seconds, send notification, and redirect
      setTimeout(async () => {
        this.showSuccessNotification = false;
        sessionStorage.removeItem('identificationNumber')
        this.insertNotification();
        this.$router.push('/meetingManagementPage'); // Redirect after hiding the notification
      }, 1700);

    } catch (error) {
      console.error('Error inserting data:', error.message);

      // Show the fail notification if insertion fails
      this.showFailNotification = true;

      // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
      setTimeout(() => {
        this.showFailNotification = false;
        this.showValidationError = true;
      }, 1700);

    } finally {
      // Set isLoading to false after the data submission process is completed
      this.isLoading = false;
    }

  } else {
    // Show the fail notification if any field is empty or there are validation errors
    this.showFailNotification = true;

    // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
    setTimeout(() => {
      this.showFailNotification = false;
      this.showValidationError = true;
    }, 1700);
    return; // Stop the function execution here
  }
},

    // Function to filter the available start times for meetings
    filteredStartTimes() {
  // Import Moment.js library
  const moment = require('moment');

  // Filter out unavailable dates that don't match the current meeting room
  const unavailableDates = this.unavailableDates.filter((e) => e.meetingRoom === this.meetingRoom);

  // Iterate over unavailable dates to remove booked time slots
  unavailableDates.forEach(unavailableDate => {
    // Convert start and end times to Moment.js objects
    const startTime = moment(unavailableDate.startTime, 'h:mm A');
    const endTime = moment(unavailableDate.endTime, 'h:mm A');

    // Exclude booked time slots from the available times
    if (unavailableDate.identificationNumber !== this.identificationNumber) {
      // Iterate through each time slot
      this.timeArray = this.timeArray.filter((time) => {
        // Check if the time slot falls between the start and end time (excluding end time)
        // The fourth parameter, '[)', is a boundary specifier that includes the start time but excludes the end time.
        return !moment(time, 'h:mm A').isBetween(startTime, endTime, undefined, '[)');
      });
    }
  });

  // If meeting date is today and there is a booked meeting for this date, include it in the list
  const currentDate = new Date().toISOString().split('T')[0];
  if (this.meetingDate === currentDate) {
    const bookedMeeting = this.unavailableDates.find((date) => date.meetingRoom === this.meetingRoom && date.meetingDate === currentDate && date.identificationNumber === this.identificationNumber);
    if (bookedMeeting) {
      const bookedStartTime = moment(bookedMeeting.startTime, 'h:mm A');
      const bookedEndTime = moment(bookedMeeting.endTime, 'h:mm A');

      // Include the booked time range in the list of available times
      for (let time = bookedStartTime.clone(); time.isBefore(bookedEndTime); time.add(30, 'minutes')) {
        const formattedTime = time.format('h:mm A');
        if (!this.timeArray.includes(formattedTime)) {
          this.timeArray.push(formattedTime);
        }
      }
    }
  }

  // Sort the timeArray
  this.timeArray.sort((a, b) => moment(a, 'h:mm A').diff(moment(b, 'h:mm A')));

  // Add times for the current meeting
  const currentMeetingStartTime = moment(this.startTime, 'h:mm A');
  const currentMeetingEndTime = moment(this.endTime, 'h:mm A');
  for (let time = currentMeetingStartTime.clone(); time.isBefore(currentMeetingEndTime); time.add(30, 'minutes')) {
    const formattedTime = time.format('h:mm A');
    if (!this.timeArray.includes(formattedTime)) {
      this.timeArray.push(formattedTime);
    }
  }

  // Remove the last time based on the meeting duration 
  for (let i = this.meeting_duration * 2; i >= 1; i--) { // multiply by 2 to account for 30 minute increments
    this.timeArray.pop();
  }

// Copy the filtered time array to the result array
this.filteredTimes = [...this.timeArray];



// Add the time slots between remainStartTime and remainEndTime
let currentTime = moment(this.remainStartTime, 'h:mm A');
const endTime = moment(this.remainEndTime, 'h:mm A');
while (currentTime.isBefore(endTime)) {
  const formattedTime = currentTime.format('h:mm A');
  if (!this.filteredTimes.includes(formattedTime)) {
    this.filteredTimes.push(formattedTime);
  }
  currentTime.add(30, 'minutes');
}

// Sort the filteredTimes array
this.filteredTimes.sort((a, b) => moment(a, 'h:mm A').diff(moment(b, 'h:mm A')));
  

  // Calculate and set the time range
  this.timeRange();
},








    // Function to asynchronously insert a notification into the database
    async insertNotification() {

      // Prepare the notification object
      const notification = {
        by: sessionStorage.getItem('userName'), // Get the sender's username from sessionStorage
        notificationType: 'editMeeting', // Set the notification type to 'editMeeting'
        identificationNumber: this.identificationNumber, // Include the identification number associated with the meeting
        readed_by: [], // Initialize an empty array for users who have read the notification
      };

      // Send the notification to the Supabase notification table
      await supabase
        .from('notification')
        .insert([notification])
        .then(result => {
          // Check if there is an error in the result
          if (result.error) {
            console.error('Failed to send notification:', result.error); // Log the error if there is one
          }
        }).catch(error => {
          console.error('Error sending notification:', error); // Log any error that occurred during the process
        });
        
    },
  },
  mixins: [filter],
  async created() {
    // Retrieve the meeting room names from the 'Hall' table in the database
    let { data: Hall } = await supabase
      .from('Hall')
      .select('hallName')
      .eq('hallStatus', 'فعال')
    this.meetingRooms.push(...Hall.map((e) => e.hallName))

    // Check if identificationNumber is stored in the sessionStorage
    if (sessionStorage.getItem('identificationNumber') == null) {
      // If not stored, set the identificationNumber from the route parameter
      this.identificationNumber = this.$route.params.meetingData.identificationNumber
      sessionStorage.setItem('identificationNumber', this.identificationNumber);
    } else {
      // If stored, check if identificationNumber is available in the route parameter
      if (this.$route.params.meetingData && this.$route.params.meetingData.identificationNumber) {
        this.identificationNumber = this.$route.params.meetingData.identificationNumber
        sessionStorage.setItem('identificationNumber', this.identificationNumber);
      } else {
        // If not available in the route parameter, retrieve it from the sessionStorage
        this.identificationNumber = sessionStorage.getItem('identificationNumber');
      }
    }

    // Retrieve the Meeting data based on identificationNumber
    let { data: Meeting } = await supabase
      .from('Meeting')
      .select('*')
      .eq('identificationNumber', this.identificationNumber)

    // Assign Meeting data to component properties
    this.entityType = Meeting[0].entityType;
    this.reservationHolderName = Meeting[0].reservationHolderName;
    this.entityActivity = Meeting[0].entityActivity
    this.phoneNumber = Meeting[0].phoneNumber;
    this.entityName = Meeting[0].entityName;
    this.meetingRoom = Meeting[0].meetingRoom;
    this.startTime = Meeting[0].startTime;


    this.remainStartTime = Meeting[0].startTime;
    this.remainEndTime = Meeting[0].endTime;



    this.endTime = Meeting[0].endTime;
    this.filteredTimes.push(this.startTime)



    this.meetingStatus = Meeting[0].meetingStatus;
    this.meetingDate = Meeting[0].meetingDate;

    this.meetingPpl = Meeting[0].meetingPpl;






    // If the entityType is 'داخلي', retrieve entity names from the 'Beneficiary' table
    if (this.entityType == 'داخلي') {
      let { data: Beneficiary } = await supabase
        .from('Beneficiary')
        .select('entityName')
        .eq('beneficiaryStatus', 'فعال')
      this.entitys.push(...Beneficiary.map((e) => e.entityName))

      // Check if the entityName exists or not
      if (!this.entitys.includes(this.entityName)) {
        this.entitys.push(this.entityName);
      }
    }

    // Check if the meetingRoom exists or not
    if (!this.meetingRooms.includes(this.meetingRoom)) {
      this.meetingRooms.push(this.meetingRoom);
    }

    this.available_hours();
    this.timeRange();
    this.meeting_duration = Meeting[0].meeting_duration

    this.oldMeetingStatus = this.meetingStatus; // Store the old status

    // Retrieve unavailable dates based on meetingDate and meetingStatus
    let { data: unavailableDates } = await supabase
      .from('Meeting')
      .select('*')
      .eq('meetingDate', this.meetingDate)
      .in('meetingStatus', ['محجوز', 'معلق']);
    this.unavailableDates = unavailableDates;

    // Scroll to the top of the page
    window.scrollTo(0, 0);

  },
}
</script>

<style scoped></style>