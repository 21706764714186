<template v-if="loginModalOpen">
  <div>
    <!-- Background overlay -->
    <div
      class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50"
    ></div>

    <!-- Modal container -->
    <div class="fixed z-50 inset-0 flex items-center justify-center">
      <!-- Modal content -->
      <div class="bg-white w-96 rounded-lg shadow-xl">
        <!-- Modal header -->
        <div class="bg-gray-800 text-white py-4 px-6 rounded-t-lg">
          <h3 class="text-lg font-semibold">تسجيل الدخول</h3>
        </div>

        <!-- Modal body -->
        <div class="p-6">
          <!-- Login form -->
          <form @submit.prevent="handleLogin">
            <div class="mb-4">
              <label for="email" class="block text-gray-700 font-semibold mb-2"
                >الايميل</label
              >
              <input
                type="email"
                id="email"
                v-model="email"
                class="border-2 w-full border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-400"
              />
            </div>
            <div class="mb-6">
              <label
                for="password"
                class="block text-gray-700 font-semibold mb-2"
                >كلمة المرور</label
              >
              <input
                type="password"
                id="password"
                v-model="password"
                class="border-2 w-full border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-400"
              />
            </div>
            <div v-if="invalidEmail">
              <p class="text-red-700 font-semibold mb-4 pr-1">
                المستخدم غير موجود او كلمة المرور خاطئة
              </p>
            </div>
            <div v-if="inactiveAccount">
              <p class="text-red-700 font-semibold mb-4 pr-1">
                يجب تفعيل الحساب لتتمكن من الدخول
              </p>
            </div>
            <div class="flex gap-4 justify-center">
              <button
                type="submit"
                class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                ارسال
              </button>

              <!-- Close button -->
              <button
                @click.prevent="closeLogin"
                class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                اغلاق
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from "../../supabase";
export default {
  name: "loginPage",
  data() {
    return {
      email: "",
      password: "",
      loginModalOpen: false,
      invalidEmail: false,
      inactiveAccount: false,
    };
  },
  mounted() {
    this.loginModalOpen = this.$store.state.isLoggedIn;
  },
  methods: {
    async handleLogin() {
  try {
    // Reset error flags and enable login button
    this.invalidEmail = false;
    this.inactiveAccount = false;
    this.loginDisabled = false;

    // Attempt to sign in with Supabase
    const { data } = await supabase.auth.signInWithPassword({
      email: this.email,
      password: this.password,
    });

    // Log user data for debugging
    console.log(data);

    // Handle cases where user does not exist or is inactive
    if (!data.user) {
      this.invalidEmail = true; // Display error for invalid email
      return;
    }

    const { user_type, user_status, user_name } = data.user.user_metadata;

    if (user_status !== "فعال") {
      this.inactiveAccount = true; // Display error for inactive account
      return;
    }

    // Authentication successful

    // Store session data
    sessionStorage.setItem("Signed", "true");
    this.$store.dispatch("updateLoginState", false);
    sessionStorage.setItem("UserType", user_type);
    sessionStorage.setItem("userName", user_name);

    // Wait for session data to update before navigating
    await this.$nextTick();

    // Redirect to home2 after successful login
    this.$router.push("/home2");
    this.loginModalOpen = false; // Close the login window
  } catch (error) {
    console.error("An error occurred during login:", error);
    this.error = "An error occurred during login. Please try again.";
  }
},


    closeLogin() {
      location.reload(); // Reload the page
    },
  },
};
</script>
