import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'

import VeeValidate from 'vee-validate';

const config = {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  dictionary: 'ar',
  errorBagName: 'errors', // change if property conflicts
  events: 'input|blur',
  fieldsBagName: 'fields',
  i18n: null, // the vue-i18n plugin instance
  i18nRootKey: 'validations', // the nested key under which the validation messages will be located
  inject: true,
  locale: 'ar',
  validity: false,
  useConstraintAttrs: false
};

Vue.use(VeeValidate, config);

// Overwriting Error Messages
import { Validator } from 'vee-validate';

Validator.extend('englishWordNumberSymbol', {
  getMessage() {
    // return `The ${field} field may only contain English letters, numbers, and symbols.`;
    return `هذا الحقل لا يقبل سوى احرف انجليزية وارقام و رموز`;
  },
  validate(value) {
    const regex = /^[A-Za-z0-9\s!@#$%^&*]+$/    ;
    return regex.test(value);
  },
});

Validator.extend('hallName', {
  getMessage() {
    return 'هذا الحقل لا يقبل سوى حروف وأرقام ومسافات';
  },
  validate(value) {
    const regex = /^[\u0600-\u06FF\sA-Za-z0-9]+$/;
    return regex.test(value);
  },
});

const dictionary = {
  ar: {
    messages: {
      required: 'هذا الحقل مطلوب',
      // min: 'الحقل يجب ان يحتوي على 10 احرف على الأقل',
      min: 'الحقل لا يقبل حروف اقل من 10',
      max: 'الحقل لا يقبل حروف اكثر من 10',
      // email: 'يجب أن يكون حقل البريد الإلكتروني بريدًا إلكترونيًا صالحًا',
      email: 'أدخل ايميل صالح',
      alpha_dash: 'هذا الحقل لا يقل سوى حروف وارقام و (-,_)',
      alpha_spaces: 'هذا الحقل لا يقبل سوى حروف ومسافات',
      numeric: 'هذا الحقل لا يقبل سوى أرقام',
    },
    custom: {
      userPassword: {
        min: 'الحقل لا يقبل حروف اقل من 8',
      },
      available_hours: {
        max_value: 'هذا الحقل لا يقبل قيمة اكبر من 4'
      }
    }
  }
};

// Override and merge the dictionaries
Validator.localize(dictionary);



const validator = new Validator();

validator.localize('ar'); // now this validator will generate messages in Arabic.


// Vue
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
