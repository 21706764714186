<template>
  <div>

    <!-- fillter -->
    <div class=" w-full border-[1px] border-[#D5D5D5] rounded-lg bg-[#F9F9FB] flex items-center h-[63.2px] mb-6">
      <div class="border-l-[1px] p-4 grow">
        <div class="h-[32.2px] flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
          </svg>
        </div>
      </div>
      <div class="border-l-[1px] p-4 w-72 text-center h-full flex justify-center items-center">
        <p>فرز بواسطة</p>
      </div>
      <div class="border-l-[1px] p-4 w-72 text-center">
        <input class=" w-fit bg-transparent focus:outline-none cursor-pointer" type="Date" name="registrationDate"
          id="registrationDate" v-model="registrationDate">
      </div>
      <div class="border-l-[1px] p-4 w-72 text-center">
        <select class="w-fit bg-transparent focus:outline-none cursor-pointer" name="meetingStatus" id="meetingStatus"
          v-model="meetingStatus">
          <option value="حالة المستخدم" selected>حالة الإجتماع</option>
          <option value="مكتمل">مكتمل</option>
          <option value="الاجتماع جاري">الاجتماع جاري</option>
          <option value="محجوز">محجوز</option>
          <option value="ملغي">ملغي</option>
          <option value="معلق">معلق</option>
        </select>
      </div>


      <div class="border-l-[1px] p-4 w-72 text-center">
          <input type="text" class="w-fit bg-transparent focus:outline-none cursor-pointer" name="meetingPpl" id="meetingPpl"
            v-model="meetingPpl" placeholder="البحث عن الحضور">
      </div>


      <div @click="reset" class="flex justify-center items-center gap-5 p-4 w-60 cursor-pointer">
        <div>
          <p class=" text-red-600">إعادة التهيئة</p>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6 text-red-600">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
          </svg>

        </div>
      </div>
    </div>
    <!-- fillter -->

    <!-- content tabel -->
    <div class="mb-6">
      <!-- Header -->
      <div
        class="w-full border-[1px] border-[#D5D5D5] rounded-t-lg bg-[#F9F9FB] grid grid-cols-12 items-center text-center font-bold py-4">
        <div>
          <p>رقم التعريف</p>
        </div>
        <div>
          <p>اسم صاحب الحجز</p>
        </div>
        <div>
          <p>الجهة</p>
        </div>
        <div>
          <p>نوع الجهة</p>
        </div>
        <div>
          <p>رقم الجوال</p>
        </div>
        <div>
          <p>القاعة</p>
        </div>
        <div>
          <p>التاريخ</p>
        </div>
        <div>
          <p>وقت بداية الاجتماع</p>
        </div>
        <div>
          <p>وقت نهاية الاجتماع</p>
        </div>
        <div class="custom-column">
          <p class="w-100">حالة الإجتماع</p>
        </div>
        <div>
          <p>إعدادات</p>
        </div>
        <div>
          <p>التفاصيل</p>
        </div>
      </div>
      <!-- content -->
      <div
        class="w-full border-l border-r border-b border-[1px] border-[#D5D5D5] rounded-b-lg text-center py-4 space-y-8">

        <!-- THE LOADING ICON WHILE DATA NOT COMPLETE FETCHING -->
        <div v-if="isLoading" class="flex justify-center items-center">
                <div class="text-center">
                    <div role="status" class="flex gap-2">
                        <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <!-- <span v-if="isLoading" class=" text-gray-500 font-semibold">جاري الإرسال</span> -->
                    </div>
                </div>
        </div>
        <!-- END OF THE LOADING ICON WHILE DATA NOT COMPLETE FETCHING -->

        <div class="grid grid-cols-12 items-center" v-for="data in filteredData"  :key="data.identificationNumber">
          <div>
            <p>{{ data.identificationNumber.toString().padStart(6, '0') }}</p>
          </div>
          <div>
            <p class=" font-bold">{{ data.reservationHolderName }}</p>
          </div>
          <div>
            <p>{{ data.entityName }}</p>
          </div>
          <div>
            <p>{{ data.entityType }}</p>
          </div>
          <div>
            <p dir="ltr">{{ data.phoneNumber }}</p>
          </div>
          <div>
            <p  dir="ltr">{{ data.meetingRoom }}</p>
          </div>
          <div>
            <p>{{ data.meetingDate }}</p>
          </div>
          <div>
            <p dir="ltr">{{ data.startTime }}</p>
          </div>
          <div>
            <p dir="ltr">{{ data.endTime }}</p>
          </div>
          <div>
            <p :class="{
            'w-all mx-auto py-2 rounded-lg bg-opacity-20': true,
            'bg-[#00B69B] text-[#00B69B]': data.meetingStatus == 'مكتمل',
            'bg-[#FFA756] text-[#FFA756]': data.meetingStatus == 'الاجتماع جاري',
            'bg-[#0E22D3] text-[#0E22D3]': data.meetingStatus == 'محجوز',
            'bg-[#EA0234] text-[#EA0234]': data.meetingStatus == 'ملغي',
            'bg-[#AEAEAE] text-[#AEAEAE]': data.meetingStatus == 'معلق'
          }">
              {{ data.meetingStatus }}
            </p>
          </div>
          <div>
            <div @click="navigateToEditBooking(data)"
              class=" cursor-pointer w-fit mx-auto py-1 px-5 border-[1px] border-[#D5D5D5] rounded-lg bg-[#F9F9FB] text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
              </svg>
            </div>
          </div>
          <!-- meeting data -->
          <div>
            <div @click="modalView(data); modalDiv = true"
              class=" z-20 cursor-pointer w-fit mx-auto py-1 px-5 border-[1px] border-[#D5D5D5] rounded-lg bg-[#F9F9FB] text-primary">
              <svg viewBox="0 0 1024 1024" class="w-6" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path fill="#ED8526"
                    d="M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 110 448 224 224 0 010-448zm0 64a160.192 160.192 0 00-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z">
                  </path>
                </g>
              </svg>
            </div>
          </div>
          <!-- end of meeting data -->

        </div>
      </div>
    </div>
    <!-- content tabel -->

    <!-- modalView -->
    <div v-click-outside="handleClickOutside" v-if="modalDiv"
      class="fixed rounded-2xl shadow-lg z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] h-[570px] bg-gradient-to-r from-yellow-400 to-orange-500">
      <div class="bg-white rounded-2xl shadow-lg p-8 text-center">
        <h2 class="text-3xl font-bold mb-4 text-orange-500">معلومات الاجتماع</h2>
        <!-- Bind meeting info from the store to display -->
        <p class="mb-2 text-center  flex gap-5  justify-start">
          <strong>رقم التعريف:</strong> {{ meetingInfo.identificationNumber }}
        </p>
        <p class="mb-2 text-center flex gap-5  justify-start">
          <strong>اسم صاحب الحجز:</strong> {{ meetingInfo.reservationHolderName }}
        </p>
        <p class="mb-2 text-center flex gap-5  justify-start">
          <strong>اسم الجهة:</strong> {{ meetingInfo.entityName }}
        </p>
        <p class="mb-2 text-center flex gap-5  justify-start">
          <strong>نوع الجهة:</strong> {{ meetingInfo.entityType }}
        </p>
        <p class="mb-2 text-center flex gap-5  justify-start">
          <strong>نشاط الجهة:</strong> {{ meetingInfo.entityActivity }}
        </p>
        <p class="mb-2 text-center flex gap-5  justify-start">
          <strong>رقم الهاتف:</strong> {{ meetingInfo.phoneNumber }}
        </p>
        <p class="mb-2 text-center flex gap-5  justify-start">
          <strong>غرفة الاجتماع:</strong> {{ meetingInfo.meetingRoom }}
        </p>
        <p class="mb-2 text-center flex gap-5  justify-start">
          <strong>تاريخ الاجتماع:</strong> {{ meetingInfo.meetingDate }}
        </p>
        <p class="mb-2 text-center flex gap-5  justify-start">
          <strong>مدة الاجتماع:</strong> {{ meetingInfo.meeting_duration }}
        </p>
        <p class="mb-2 text-center flex gap-5  justify-start">
          <strong>وقت البدء:</strong> {{ meetingInfo.startTime }}
        </p>
        <p class="mb-2 text-center flex gap-5  justify-start">
          <strong>وقت الانتهاء:</strong> {{ meetingInfo.endTime }}
        </p>
        <p class="mb-2 text-center flex gap-5  justify-start">
          <strong>حالة الاجتماع:</strong> {{ meetingInfo.meetingStatus }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>أعضاء الاجتماع:</strong> {{ meetingInfo.meetingPpl }}
        </p>
        <button @click="modalDiv = false"
          class="bg-orange-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-white hover:text-orange-500">
          اغلاق
        </button>
      </div>
    </div>
    <!-- end of modalView -->

  </div>
</template>

<script>
import { mapState } from 'vuex'; // Import mapGetters from Vuex
import vClickOutside from 'v-click-outside';
import { supabase } from '../../../supabase';

export default {
  directives: {
    ClickOutside: vClickOutside.directive
  },
  data() {
    return {
      registrationDate: '',
      meetingStatus: 'حالة المستخدم',
      datas: [],
      modalDiv: false,
      meetingInfo: {},
      isLoading: false,
      meetingPpl: ""
    }
  },
  methods: {
    modalView(data) {
      if (data) {
        this.meetingInfo = data;
        this.modalDiv = true;
      } else {
        console.error("Meeting information not found:", data);
      }
    },
    handleClickOutside() {
      // This function handles the click event that occurs outside the modal.
      // It sets the modalDiv property to false, effectively closing the modal.      
      this.modalDiv = false;
    },
    reset() {
      // This function resets the state of the component.
      // It sets the meetingStatus property to 'حالة المستخدم' (user status)
      // and sets the registrationDate property to null.      this.meetingStatus = 'حالة المستخدم';
      this.registrationDate = null
      this.meetingStatus = 'حالة المستخدم'
      this.meetingPpl = null
    },
    navigateToEditBooking(data) {
      // This function navigates the user to the "editBooking" route, 
      // passing the data object as a parameter. 
      // It uses the Vue Router's $router.push method to perform the navigation.
      this.$router.push({
        name: 'editBooking',
        params: {
          meetingData: data
        }
      });
    },
    matchesDate(data) {
      // This function checks if the data object's meetingDate matches the selected date (registrationDate).
      // If no date is selected (registrationDate is falsy), it returns true indicating that all dates are considered a match.
      // Otherwise, it compares the meetingDate with the selected date and returns true if they are equal.
      if (!this.registrationDate) {
        return true; // No date selected, so all dates are considered a match
      }
      const selectedDate = new Date(this.registrationDate).toISOString().split('T')[0];
      return data.meetingDate === selectedDate;
    },
    matchesMeetingStatus(data) {
      // This function checks if the data object's meetingStatus matches the selected meeting status (meetingStatus).
      // If no specific meeting status is selected (meetingStatus is 'حالة المستخدم'),
      // it returns true indicating that all statuses are considered a match.
      // Otherwise, it compares the meetingStatus with the selected status and returns true if they are equal.
      if (this.meetingStatus === 'حالة المستخدم') {
        return true; // No specific user status selected, so all statuses are considered a match
      }
      return data.meetingStatus === this.meetingStatus;
    },
    matchesMeetingPpl(data) {
  if (!this.meetingPpl) {
    return true;
  }
  
  // Create a regular expression to search for the 'meetingPpl' value
  const regex = new RegExp(this.meetingPpl, 'i');
  
  // Check if the 'meetingPpl' field in the data matches the regular expression
  return regex.test(data.meetingPpl);
}
,
    async startAutomaticFunction() {
  setInterval(async () => {
    const now = new Date();
    const date = now.toISOString().split('T')[0];
    const currentTime = now.getHours() * 60 + now.getMinutes();

    for (const e of this.datas) {
      // Check if the meeting date is before the current date
      if (new Date(e.meetingDate) < now && e.meetingDate !== date) {
        if (e.meetingStatus === 'محجوز') {
          e.meetingStatus = 'مكتمل';
          await supabase
            .from('Meeting')
            .update({ meetingStatus: 'مكتمل' })
            .eq('identificationNumber', e.identificationNumber)
            .select();
        }
      } else if (e.meetingDate === date) {
        const startTime = new Date(`${e.meetingDate} ${e.startTime}`).getHours() * 60 + new Date(`${e.meetingDate} ${e.startTime}`).getMinutes();
        const endTime = new Date(`${e.meetingDate} ${e.endTime}`).getHours() * 60 + new Date(`${e.meetingDate} ${e.endTime}`).getMinutes();

        // Check if the current time is at or after the meeting start time
        if (currentTime >= startTime) {
          if (currentTime < endTime) {
            if (e.meetingStatus === 'محجوز') {
              e.meetingStatus = 'الاجتماع جاري';
              await supabase
                .from('Meeting')
                .update({ meetingStatus: 'الاجتماع جاري' })
                .eq('identificationNumber', e.identificationNumber)
                .select();
            }
          } else if (currentTime >= endTime) {
            if (e.meetingStatus === 'الاجتماع جاري' || e.meetingStatus === 'محجوز') {
              e.meetingStatus = 'مكتمل';
              await supabase
                .from('Meeting')
                .update({ meetingStatus: 'مكتمل' })
                .eq('identificationNumber', e.identificationNumber)
                .select();
            }
          }
        }
      }
    }

    const remainingTime = (60 - now.getMinutes()) * 60 * 1000 - now.getSeconds() * 1000;
    if (remainingTime <= 1000) {
      await this.executeFunction();
    }
  }, 1000);
},

async executeFunction() {
  const d = new Date().toISOString().split('T')[0];
  // const currentHour = `${new Date().getHours()}:00 ${new Date().getHours() >= 12 ? 'PM' : 'AM'}`;

  const { data: Meeting } = await supabase
    .from('Meeting')
    .select('*')
    .eq('meetingDate', d);

  for (const e of Meeting) {
    const startTime = new Date(`${e.meetingDate} ${e.startTime}`).getHours() * 60 + new Date(`${e.meetingDate} ${e.startTime}`).getMinutes();
    const endTime = new Date(`${e.meetingDate} ${e.endTime}`).getHours() * 60 + new Date(`${e.meetingDate} ${e.endTime}`).getMinutes();
    const currentTime = new Date().getHours() * 60 + new Date().getMinutes();

    if (currentTime >= startTime && currentTime < endTime) {
      if (e.meetingStatus !== 'الاجتماع جاري') {
        await supabase
          .from('Meeting')
          .update({ meetingStatus: 'الاجتماع جاري' })
          .eq('identificationNumber', e.identificationNumber)
          .select();

        this.datas.forEach((el) => {
          if (el.meetingDate === e.meetingDate && el.startTime === e.startTime) {
            el.meetingStatus = 'الاجتماع جاري';
          }
        });
      }
    } else if (currentTime >= endTime) {
      if (e.meetingStatus !== 'مكتمل') {
        await supabase
          .from('Meeting')
          .update({ meetingStatus: 'مكتمل' })
          .eq('identificationNumber', e.identificationNumber)
          .select();

        this.datas.forEach((el) => {
          if (el.meetingDate === e.meetingDate && el.startTime === e.startTime) {
            el.meetingStatus = 'مكتمل';
          }
        });
      }
    }
  }
}
  },
  computed: {
    filteredData() {
      return this.datas.filter(data => this.matchesDate(data) && this.matchesMeetingStatus(data) && this.matchesMeetingPpl(data));
    },
    ...mapState(['meetingData'])
  },
  async created() {
  // Set isLoading to true before starting the data fetching process
  this.isLoading = true;

  try {
    const { data: Meeting, error } = await supabase
      .from('Meeting')
      .select('*')
      .order('meetingDate', { ascending: true });

    if (error) {
      // Handle the error, such as displaying an error message
      console.error('Error fetching Beneficiary data:', error);
    } else {
      // Assign the fetched data to the component's `datas` property
      this.datas = Meeting;
    }
  } catch (error) {
    // Handle any errors that occur during the data fetching process
    console.error('An error occurred while fetching Beneficiary data:', error);
  } finally {
    // Set isLoading to false after the data fetching process is completed
    this.isLoading = false;
  }

  // Call the startAutomaticFunction method to start the automatic function
  this.startAutomaticFunction();
}


}
</script>

<style scoped></style>