import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    modalInNotification: false, // related to the modal in the notification to open and close the modal info
    modalAdintification: '', // related to the modal in the notification to use it in the modal info in the notification to fetch data
    modalType: '', // related to the modal in the notification to use it in the modal info in the notification to specify modal type 
    openComponentModal: false,
    user_name: ''

  },
  getters: {
    userName(state) {
      return state.user_name;
    }
  },
  
  mutations: {
    setLoginState(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setModalState(state, modalInNotification) {
      state.modalInNotification = modalInNotification;
    },
    setModalIDState(state, modalAdintification) {
      state.modalAdintification = modalAdintification;
    },
    setModalTypeState(state, modalType) {
      state.modalType = modalType;
    },
    setModalComponent(state, openComponentModal) {
      state.openComponentModal = openComponentModal;
    },
    setUser_name(state, user_name) {
      state.user_name = user_name;
    }

  },
  actions: {

    updateLoginState({ commit }, isLoggedIn) {
      commit('setLoginState', isLoggedIn);
    },
    updateModalState({ commit }, modalInNotification) {
      commit('setModalState', modalInNotification);
    },
    updateIDModalState({ commit }, modalAdintification) {
      commit('setModalIDState', modalAdintification);
    },
    updateTypeModalState({ commit }, modalType) {
      commit('setModalTypeState', modalType);
    },
    updateModalComponent({ commit }, openComponentModal) {
      commit('setModalComponent', openComponentModal);
    },


    
  },
  modules: {
  }
})