export default {
  methods: {
    validateAvailableHours() {
      if (this.available_hours > 4) {
        this.available_hours = 4;
      }
    },
  },
  computed: {
    // Function to generate start time options excluding '8:00 PM' 
    startTimeOptions() {
      // Filter out '8:00 PM' from the times array
      return this.times.filter(time => time.time !== '9:00 PM');
    },
    // Function to generate end time options based on the selected start time
    endTimeOptions() {
      // Check if a start time is selected
      if (this.startTime) {
        // Find the index of the selected start time in the times array
        const startIndex = this.times.findIndex(time => time.time === this.startTime);
        // Return a slice of times array starting from the index next to the selected start time
        return this.times.slice(startIndex + 1);
      } else {
        // If no start time is selected, return the original times array
        return this.times;
      }
    },
  }
}