<template>
  <div class="flex flex-col items-center justify-center h-screen bg-gray-100 rtl">
    <div class="bg-white shadow-lg rounded-lg p-6 w-full max-w-md text-center sm:p-8">
      <svg class="h-12 w-12 text-red-500 mx-auto mb-4 sm:h-16 sm:w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <h1 class="text-3xl font-bold text-red-500 mb-2 sm:text-4xl">404</h1>
      <p class="text-gray-700 mb-0 text-sm sm:text-base">
        الصفحة التي تبحث عنها ({{ $route.fullPath }}) غير موجودة.
      </p>
      <button @click="goToHomePage" class="bg-red-500 text-white px-4 py-2 rounded-md mt-4 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">
        انتقل إلى الصفحة الرئيسية
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  methods: {
    goToHomePage() {
      const isSignedIn = sessionStorage.getItem('Signed');
      if (isSignedIn === 'true') {
        this.$router.push('/home2');
      } else {
        this.$router.push('/');
      }
    }
  }
}
</script>

<style scoped>
.rtl {
  direction: rtl;
}

@media (max-width: 767px) {
  .bg-white {
    max-width: 100%;
    padding: 1.5rem;
  }

  .h-12 {
    height: 3rem;
  }

  .w-12 {
    width: 3rem;
  }

  .text-3xl {
    font-size: 1.75rem;
  }

  .text-sm {
    font-size: 0.875rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .bg-white {
    max-width: 80%;
  }
}

@media (min-width: 1024px) {
  .bg-white {
    max-width: 50%;
  }
}
</style>