<template>
  <div class="notification_success notification">
    <div class="flex justify-center items-center flex-col gap-2">
      <div class="identifier">
        <svg class="w-7" viewBox="0 0 117 117" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <title></title>
            <desc></desc>
            <defs></defs>
            <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
              <g fill-rule="nonzero" id="correct">
                <path
                  d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z"
                  fill="#ED8526" id="Shape"></path>
                <path
                  d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z"
                  fill="#ED8526" id="Shape"></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class=" text-primary font-extrabold">تم إكمال العملية</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.notification {
  animation: notify 3s ease-in-out 1;
}

@keyframes notify {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  10% {
    transform: translate(-50%, -50%) scale(1);
  }

  13% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  16% {
    transform: translate(-50%, -50%) scale(1);
  }

  55% {
    transform: translate(-50%, -50%) scale(1);
  }

  65% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  95% {
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>