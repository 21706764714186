import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/homePage.vue'
import Home2 from '../views/homePage2.vue'
import userManagement from '../views/userManagementPage.vue'
import hallManagement from '../views/hallManagementPage.vue'
import meetingManagement from '../views/meetingManagementPage.vue'
import beneficiaryManagement from '../views/beneficiaryManagementPage.vue'
import PageNotFound from '../views/PageNotFound.vue'
import internalBooking from '../components/Form/internalBooking.vue'
import externalBooking from '../components/Form/externalBooking.vue'
import editBooking from '../components/edit/editBooking.vue'
import editeUser from '../components/edit/editeUser.vue'
import editeHall from '../components/edit/editeHall.vue'
import editeBeneficiary from '../components/edit/editeBeneficiary.vue'
import userForm from '../components/Form/userForm.vue'
import hallManagementForm from '../components/Form/hallManagementForm.vue'
import beneficiaryManagementForm from '../components/Form/beneficiaryManagementForm.vue'





Vue.use(VueRouter)

const routes = [
  { path: '*', component: PageNotFound },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/home2',
    name: 'home2',
    component: Home2,
    meta: { requiresAuth: true } 
    
  },
  {
    path: '/userManagementPage',
    name: 'userManagement',
    component: userManagement,
    meta: { requiresAuth: true } 
  },
  {
    path: '/hallManagementPage',
    name: 'hallManagement',
    component: hallManagement,
    meta: { requiresAuth: true } 
  },
  {
    path: '/meetingManagementPage',
    name: 'meetingManagement',
    component: meetingManagement,
    meta: { requiresAuth: true } 
  },
  {
    path: '/beneficiaryManagementPage',
    name: 'beneficiaryManagement',
    component: beneficiaryManagement,
    meta: { requiresAuth: true } 
  },
  {
    path: '/internalBooking',
    name: 'internalBooking',
    component: internalBooking,
    meta: { requiresAuth: true } 
  },
  {
    path: '/externalBooking',
    name: 'externalBooking',
    component: externalBooking,
    meta: { requiresAuth: true } 
  },
  {
    path: '/editBooking',
    name: 'editBooking',
    component: editBooking,
    meta: { requiresAuth: true } 
  },
  {
    path: '/editeUser',
    name: 'editeUser',
    component: editeUser,
    meta: { requiresAuth: true } 
  },
  {
    path: '/editeHall',
    name: 'editeHall',
    component: editeHall,
    meta: { requiresAuth: true } 
  },
  {
    path: '/editeBeneficiary',
    name: 'editeBeneficiary',
    component: editeBeneficiary,
    meta: { requiresAuth: true } 
  },
  {
    path: '/userForm',
    name: 'userForm',
    component: userForm,
    meta: { requiresAuth: true } 
  },
  {
    path: '/hallManagementForm',
    name: 'hallManagementForm',
    component: hallManagementForm,
    meta: { requiresAuth: true } 
  },
  {
    path: '/beneficiaryManagementForm',
    name: 'beneficiaryManagementForm',
    component: beneficiaryManagementForm,
    meta: { requiresAuth: true } 
  },




]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// Add navigation guard to check authentication before accessing routes
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !JSON.parse(sessionStorage.getItem("Signed"))) {
    // If route requires authentication and user is not signed in, redirect to login page
    next('/');
    
  } else if (JSON.parse(sessionStorage.getItem("Signed")) === undefined || JSON.parse(sessionStorage.getItem("Signed")) === false) {
    // If signed status is undefined or false, redirect to /
    next('/');
     
  } else {
    // Proceed to the route if it doesn't require authentication or user is signed in
    next();
  }
});
export default router
