<!-- newUser ||||  newInMeeting  ||||  newExMeeting  ||||  newHall    ||||  editMeeting   ||||   editHall |||||  -->
<template>
  <div>

    <!-- modalView if it was meeting-->
    <div v-if="this.modalType == 'newInMeeting' || this.modalType == 'newExMeeting' || this.modalType == 'editMeeting'"
      class="fixed rounded-2xl shadow-lg z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] h-[570px] bg-gradient-to-r from-yellow-400 to-orange-500">

      <div class="bg-white flex flex-col  w-[500px] h-[570px] rounded-2xl shadow-lg p-8 text-center">

        <h2 class="text-3xl font-bold mb-4 text-orange-500">
          معلومات الاجتماع
        </h2>

        <!-- Bind meeting info from the store to display -->
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>رقم التعريف:</strong> {{ meetingInfo.identificationNumber }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>اسم صاحب الحجز:</strong>
          {{ meetingInfo.reservationHolderName }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>اسم الجهة:</strong> {{ meetingInfo.entityName }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>نوع الجهة:</strong> {{ meetingInfo.entityType }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>نشاط الجهة:</strong> {{ meetingInfo.entityActivity }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>رقم الهاتف:</strong> {{ meetingInfo.phoneNumber }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>غرفة الاجتماع:</strong> {{ meetingInfo.meetingRoom }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>تاريخ الاجتماع:</strong> {{ meetingInfo.meetingDate }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>مدة الاجتماع:</strong> {{ meetingInfo.meeting_duration }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>وقت البدء:</strong> <span dir="ltr">{{ meetingInfo.startTime }}</span>
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>وقت الانتهاء:</strong> <span dir="ltr">{{ meetingInfo.endTime }}</span>
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>حالة الاجتماع:</strong> {{ meetingInfo.meetingStatus }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>أعضاء الاجتماع:</strong> {{ meetingInfo.meetingPpl }}
        </p>
        <button @click="closeModal"
          class="bg-orange-500 self-center w-20 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-white hover:text-orange-500">
          اغلاق
        </button>

      </div>

    </div>
    <!-- end of modalView if it was meeting-->

    <!-- If the notificarion was newUser the modal will be ... -->
    <div v-if="this.modalType == 'newUser'"
      class="fixed rounded-2xl shadow-lg z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] h-[500px] bg-gradient-to-r from-yellow-400 to-orange-500">
      <div class="bg-white flex flex-col gap-3 w-[500px] h-[500px]  rounded-2xl shadow-lg p-8 text-center">
        <h2 class="text-3xl font-bold mb-4 text-orange-500">
          معلومات المستخدم
        </h2>
        <!-- Bind meeting info from the store to display -->
        <p v-if="userInfo.raw_user_meta_data" class="mb-2 text-center flex gap-5 justify-start">
          <strong>رقم التعريف:</strong> {{ userInfo.raw_user_meta_data.id_auto }}
        </p>
        <p v-if="userInfo.raw_user_meta_data" class="mb-2 text-center flex gap-5 justify-start">
          <strong>البريد الالكتروني :</strong> {{ userInfo.email }}
        </p>
        <p v-if="userInfo.raw_user_meta_data" class="mb-2 text-center flex gap-5 justify-start">
          <strong>اسم المستخدم :</strong> {{ userInfo.raw_user_meta_data.user_name }}
        </p>
        <p v-if="userInfo.raw_user_meta_data" class="mb-2 text-center flex gap-5 justify-start">
          <strong>نوع المستخدم :</strong> {{ userInfo.raw_user_meta_data.user_type }}
        </p>
        <p v-if="userInfo.raw_user_meta_data" class="mb-2 text-center flex gap-5 justify-start">
          <strong>حالة المستخدم :</strong> {{ userInfo.raw_user_meta_data.user_status }}
        </p>
        <p v-if="userInfo.raw_user_meta_data" class="mb-2 text-center flex gap-5 justify-start">
          <strong>رقم الجوال :</strong> {{ userInfo.raw_user_meta_data.phone_number }}
        </p>
        <p v-if="userInfo.raw_user_meta_data" class="mb-2 text-center flex gap-5 justify-start">
          <strong>المسمى الوظيفي :</strong> {{ userInfo.raw_user_meta_data.job_title }}
        </p>
        <!--start close button -->
        <button @click="closeModal"
          class="bg-orange-500 self-center w-20 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-white hover:text-orange-500">
          اغلاق
        </button>
        <!-- end close button -->


      </div>
    </div>
    <!-- end of newUser modal -->

    <!-- modalView if it was hall-->
    <div v-if="this.modalType == 'newHall' || this.modalType == 'editHall'"
      class="fixed rounded-2xl shadow-lg z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] h-[500px] bg-gradient-to-r from-yellow-400 to-orange-500">
      <div class="bg-white flex flex-col gap-3 w-[500px] h-[500px] rounded-2xl shadow-lg p-8 text-center">
        <h2 class="text-3xl font-bold mb-4 text-orange-500">
          معلومات القاعة
        </h2>
        <!-- Bind hall info from the store to display -->
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>رقم التعريف:</strong> {{ hallInfo.identificationNumber }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>اسم الفاعة:</strong>
          {{ hallInfo.hallName }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong> عدد الأشخاص المتاح:</strong> {{ hallInfo.pplNumber }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong> عدد ساعات الحجز:</strong> {{ hallInfo.available_hours }}
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>وقت البدء:</strong> <span dir="ltr">{{ hallInfo.startTime }}</span>
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>وقت الانتهاء:</strong> <span dir="ltr">{{ hallInfo.endTime }}</span>
        </p>
        <p class="mb-2 text-center flex gap-5 justify-start">
          <strong>حالة الاجتماع:</strong> {{ hallInfo.hallStatus }}
        </p>
        <!--start close button -->
        <button @click="closeModal"
          class="bg-orange-500 self-center w-20 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-white hover:text-orange-500">
          اغلاق
        </button>
        <!-- end close button -->
      </div>
    </div>
    <!-- end of modalView if it was hall-->

  </div>
</template>

<script>
import { supabase } from "../../supabase";

export default {
  data() {
    return {
      meetingInfo: {},
      userInfo: {},
      hallInfo: {},
      modalDiv: false,
      modalType: '',
      modalID: ''
    };
  },
  methods: {
    async fetchMeeting() {
      try {

        this.modalID = +this.$store.state.modalAdintification; // Update modalID from the store
        let { data, error } = await supabase
          .from("Meeting")
          .select("*")
          .eq("identificationNumber", this.modalID);

        if (error) {
          // Handle the error, such as displaying an error message
          console.error("Error fetching Meeting data:", error);
        } else if (data && data.length > 0) {
          // Assign the fetched data to the component's `meetingInfo` property
          this.meetingInfo = data[0];
        } else {
          console.log("No meeting data found for the given identification number.");
        }
      } catch (error) {
        // Handle any errors that occur during the data fetching process
        console.error("An error occurred while fetching Meeting data:", error);
      }
    },
    async fetchHall() {
      try {
        this.modalID = +this.$store.state.modalAdintification; // Update modalID from the store
        let { data, error } = await supabase
          .from("Hall")
          .select("*")
          .eq("identificationNumber", this.modalID);
        if (error) {
          // Handle the error, such as displaying an error message
          console.error("Error fetching hall data:", error);
        } else if (data && data.length > 0) {
          // Assign the fetched data to the component's `hallInfo` property
          this.hallInfo = data[0];
        } else {
          console.log("No hall data found for the given identification number.");
        }
      } catch (error) {
        // Handle any errors that occur during the data fetching process
        console.error("An error occurred while fetching hall data:", error);
      }
    },
    async fetchUser() {
      try {
        this.modalID = this.$store.state.modalAdintification; // Update modalID from the store

        let { data, error } = await supabase
          .from("user_view")
          .select("*");

        if (error) {
          // Handle the error, such as displaying an error message
          console.error("Error fetching user data:", error);
        } else if (data && data.length > 0) {
          // Filter the data based on the condition
          this.userInfo = data.find(item => item.raw_user_meta_data.id_auto === this.modalID);
        } else {
          console.log("No user data found.");
        }
      } catch (error) {
        // Handle any errors that occur during the data fetching process
        console.error("An error occurred while fetching user data:", error);
      }
    },
    closeModal() {
  // Perform navigation based on notificationType
  switch (this.$store.state.modalType) {
    case 'newUser':
      if (this.$route.name !== 'userManagement') {
        this.$router.push({ name: 'userManagement' });
      }
      break;
    case 'editMeeting':
    case 'newInMeeting':
    case 'newExMeeting':
      if (this.$route.name !== 'meetingManagement') {
        this.$router.push({ name: 'meetingManagement' });
      }
      break;
    case 'newHall':
    case 'editHall':
      if (this.$route.name !== 'hallManagement') {
        this.$router.push({ name: 'hallManagement' });
      }
      break;
    default:
      // Handle any other notification types or cases
      break;
  }

  // Close the modal after navigation
  this.$store.dispatch('updateModalComponent', false);
  this.$store.dispatch('updateModalState', false);
  this.$store.dispatch('updateTypeModalState', null);
  this.$store.dispatch('updateIDModalState', null);
}

  },
  async mounted() {
    this.modalType = this.$store.state.modalType;
    this.modalID = this.$store.state.modalAdintification;
    if (this.modalType == 'newInMeeting' || this.modalType == 'newExMeeting' || this.modalType == 'editMeeting') {
      this.fetchMeeting();
    }
    if (this.modalType == 'newUser') {
      this.fetchUser();
    }
    if (this.modalType == 'newHall' || this.modalType == 'editHall') {
      this.fetchHall();
    }
    this.modalDiv = this.$store.state.modalInNotification;
  },

};
</script>
