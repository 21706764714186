<template>
  <div>

    <!-- Start notification -->
    <successNotification v-if="this.showSuccessNotification == true"></successNotification>
    <failNotification v-if="this.showFailNotification == true"></failNotification>
    <errorNotification v-if="this.showNotification == true" :NotificationMsg="NotificationMsg"></errorNotification>
    <!-- End notification -->

    <div class="flex relative">
      <menuComponent class="w-1/6"></menuComponent>
      <div class="left-0 absolute w-5/6  px-12">
        <headerComponent></headerComponent>
        <div class="mt-20 mb-10">

          <div class=" mt-20 mb-10">
            <h3 class="text-3xl font-bold mb-2">تعديل مستفيد</h3>

            <div class="border rounded-lg">
              <div class=" w-fit mx-auto my-16 space-y-10">

                <!-- Start Input fild -->
                <div class="flex justify-start gap-4">

                  <div class=" space-y-2">
                    <label class="text-[#606060]" for="entityName">اسم الجهة</label>
                    <div>
                      <input class="inputFild" type="text" name="entityName" id="entityName"
                        placeholder="أدخل اسم الجهة" v-model="entityName" v-validate="'required|alpha_spaces'">
                    </div>
                    <p class="error">{{ errors.first('entityName') }}</p>
                    <div v-if="showValidationError">
                      <p v-if="entityName == '' && errors.first('entityName') == null" class="error">
                        يرجى تعبئة الحقل
                      </p>
                    </div>
                  </div>

                  <div class=" space-y-2">
                    <label class="text-[#606060]" for="entityEmail">البريد الالكتروني</label>
                    <div>
                      <input class="inputFild" type="email" name="entityEmail" id="entityEmail"
                        placeholder="ادخل بريدك الالكتروني" v-model="entityEmail" v-validate="'required|email'">
                    </div>
                    <p class="error">{{ errors.first('entityEmail') }}</p>
                    <div v-if="showValidationError">
                      <p v-if="entityEmail == '' && errors.first('entityEmail') == null" class="error">
                        يرجى تعبئة الحقل
                      </p>
                    </div>
                  </div>

                </div>

                <div class="flex justify-start gap-4">

                  <div class=" space-y-2">
                    <label class="text-[#606060]" for="phoneNumber">رقم الجوال</label>
                    <div>
                      <input class="inputFild" type="text" name="phoneNumber" id="phoneNumber" placeholder="05"
                        v-model="phoneNumber" v-validate="'required|numeric|min:10|max:10'">
                    </div>
                    <p class="error">{{ errors.first('phoneNumber') }}</p>
                    <div v-if="showValidationError">
                      <p v-if="phoneNumber == '' && errors.first('phoneNumber') == null" class="error">
                        يرجى تعبئة الحقل
                      </p>
                    </div>
                  </div>

                  <div class=" space-y-2">
                    <label class="text-secondary" for="beneficiaryStatus">حالة الاجتماع</label>
                    <div class="w-[360px]">
                      <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-[41.6px]"
                        name="beneficiaryStatus" id="beneficiaryStatus" v-model="beneficiaryStatus">
                        <option value="فعال">فعال</option>
                        <option value="موقوف">موقوف</option>
                        <option value="معلق">معلق</option>
                      </select>
                    </div>
                  </div>

                </div>
                <!-- End Input fild -->

                <!-- Profile Picture Row -->
                <div class="flex justify-start gap-4">
                <div class="space-y-2">
                  <label class="text-secondary" for="profilePicture">شعار المستفيد</label>
                  <div class="flex gap-2 relative justify-center items-center">
                    <img v-if="oldProfilePicture && !newProfilePicture" :src="oldProfilePicture" alt="" class="w-12">
                    <img v-if="newProfilePicture" :src="newProfilePicture" alt="" class="w-8">
                    <input class="inputField" type="file" accept="image/*" name="profilePicture" id="profilePicture" @change="handleFileUpload">
                  </div>
                  <div v-if="!showValidationError && fileError" >
                    <p class="error">{{ fileError }}</p>
                  </div>
                  <div v-if="showValidationError">
                    <p v-if="fileError" class="error">
                      {{ this.fileError }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF Profile Picture Row -->

              <div v-if="isLoading || dataLoaded" class="flex justify-center items-center">
                <div class="text-center">
                    <div role="status" class="flex gap-2">
                        <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span v-if="isLoading" class=" text-gray-500 font-semibold">جاري الإرسال</span>
                        <span v-if="dataLoaded" class=" text-gray-500 font-semibold">جاري تنزيل البيانات</span>
                    </div>
                </div>
              </div>

                <!-- Start Button -->
                <div class=" text-center">
                  <button @click="submitData"
                    class="btn_hover rounded-lg bg-gradient-to-b from-[#ED8F37] to-[#ED8F37A6] px-16 py-2 text-yellow-50 text-lg">تعديل</button>
                </div>
                <!-- End Button -->

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import menuComponent from '@/components/menu-booking.vue'
import headerComponent from '@/components/headerSection.vue'
import successNotification from '@/components/Notification/successNotification.vue'
import failNotification from '@/components/Notification/failNotification.vue'
import errorNotification from '@/components/Notification/errorNotification.vue'
import { supabase } from '../../../supabase';

export default {
  name: 'beneficiaryForm',
  components: {
    menuComponent,
    headerComponent,
    successNotification,
    failNotification,
    errorNotification
  },
  data() {
    return {
      identificationNumber: '',
      entityName: '',
      entityEmail: '',
      phoneNumber: '',
      profilePicture: null, // Data property to store the profile picture file
      oldProfilePicture: null,
      newProfilePicture: null,
      fileError: '', // Data property to store file validation errors
      showSuccessNotification: false,
      showFailNotification: false,
      showValidationError: false,
      beneficiaryStatus: '',
      showNotification: false,
      NotificationMsg: '',
      isLoading: false, // icon of loading 
      dataLoaded: false
      
    }
  },
  methods: {
    handleFileUpload(event) {
  const file = event.target.files[0];
  if (!file) return;

  // Check if the file is an image
  if (!file.type.startsWith('image/')) {
    this.fileError = 'يجب تحديد ملف صورة.';
    return;
  }

  // Check file size (2MB limit)
  if (file.size > 2 * 1024 * 1024) {
    this.fileError = 'حجم الملف يجب ان يكون أقل من 2 ميجا بايت.';
    return;
  }

  // Check image dimensions
  const image = new Image();
  image.onload = () => {
    if (image.width !== 250 || image.height !== 250) {
      this.fileError = 'يجب أن يكون حجم الصورة 250x250 بيكسل.';
    } else {
      this.newProfilePicture = URL.createObjectURL(file); // Update newProfilePicture
      this.profilePicture = file; // Update the profilePicture property
      this.fileError = '';
    }
  };
  image.src = URL.createObjectURL(file);
},


    async uploadProfilePicture() {
  if (!this.profilePicture) return null;

  // Encode the filename
  const encodedFileName = encodeURIComponent(new Date().toISOString().replace(/[:.-]/g, ''));

  const { error } = await supabase.storage
    .from('profile')
    .upload(`image/${encodedFileName}`, this.profilePicture);

  if (error) {
    console.error('Error uploading file:', error);
    throw error;
  }

  try {
    const publicURL = await supabase.storage
  .from('profile')
  .getPublicUrl(`image/${encodedFileName}`);

    return publicURL.data.publicUrl;
  } catch (error) {
    console.error('Error getting public URL:', error);
    throw error;
  }
},
    // Function to submit Beneficiary data
    async submitData() {
  // Check if all fields are filled and there are no validation errors
  if (this.entityName && this.entityEmail && this.phoneNumber && this.beneficiaryStatus && !this.fileError &&
      !this.errors.has('entityName') && !this.errors.has('entityEmail') && !this.errors.has('phoneNumber')) {
      
    // Call the asynchronous function checkIfDuplicates to determine if there are any duplicate entries in the database.
    const isDuplicates = await this.checkIfDuplicates();
    // If duplicates are found (isDuplicates is true), return from the function without further processing.
    if (isDuplicates == true) {
      return;
    }

    // Set isLoading to true before starting the update process
    this.isLoading = true;

    // Proceed with update if no entry with the same email or name exists
    try {
      // Update the Beneficiary record in the Supabase database
      await supabase
        .from('Beneficiary')
        .update({ 
          entityName: this.entityName, 
          entityEmail: this.entityEmail, 
          phoneNumber: this.phoneNumber, 
          beneficiaryStatus: this.beneficiaryStatus, 
          profilePictureUrl: await this.uploadProfilePicture() 
        })
        .eq('identificationNumber', parseInt(this.identificationNumber, 10));

      // Remove the 'identificationNumber' key from the sessionStorage, effectively clearing the stored data associated with it.
      sessionStorage.removeItem('identificationNumber');

      // Show the success notification after successful insertion
      this.showSuccessNotification = true;

      // Redirect after hiding the notification
      setTimeout(() => {
        this.showSuccessNotification = false;
        this.$router.push('/beneficiaryManagementPage');
      }, 1700);

    } catch (error) {
      // Handle any errors that occur during the update process
      console.error('An error occurred while updating the Beneficiary record:', error);

      // Show the fail notification if the update fails
      this.showFailNotification = true;

      // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
      setTimeout(() => {
        this.showFailNotification = false;
        this.showValidationError = true;
      }, 1700);
    } finally {
      // Set isLoading to false after the process is completed
      this.isLoading = false;
    }
    
  } else {
    // Show the fail notification if any field is empty or there are validation errors
    this.showFailNotification = true;

    // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
    setTimeout(() => {
      this.showFailNotification = false;
      this.showValidationError = true;
    }, 1700);
    return; // Stop the function execution here
  }
},

    // Function to check for duplicate entries in the database
    async checkIfDuplicates() {

      // This section defines two asynchronous functions. 
      // The first function, submitData, submits meeting data to a database after performing validation checks and handling duplicate entries. It shows success or failure notifications accordingly.
      // The second function, checkIfDuplicates, checks for duplicate entries in the database based on entityName and entityEmail fields. It handles errors and shows alerts if duplicates are found.

      // Check if name already exists
      const { data: existingNameData, error: nameError } = await supabase
        .from('Beneficiary')
        .select('*')
        .eq('entityName', this.entityName);
      if (nameError) {
        console.error('Error fetching name data:', nameError.message);
        return true; // Stop the function execution here
      }

      // Check if email already exists
      const { data: existingEmailData, error: emailError } = await supabase
        .from('Beneficiary')
        .select('*')
        .eq('entityEmail', this.entityEmail);
      if (emailError) {
        console.error('Error fetching email data:', emailError.message);
        return true; // Stop the function execution here
      }

      // If there is already an entry with the same name, show alert
      if ((existingNameData && existingNameData.length > 0 && existingNameData[0].identificationNumber != this.identificationNumber)) {
        this.NotificationMsg = 'الجهة المدخلة موجودة مسبقا';
        this.showNotification = true
        setTimeout(() => {
          this.showNotification = false;
        }, 1700);
        return true; // Stop the function execution here
      }

      // If there is already an entry with the same email, show alert
      if ((existingEmailData && existingEmailData.length > 0 && existingEmailData[0].identificationNumber != this.identificationNumber)) {
        this.NotificationMsg = 'الايميل المدخل موجود مسبقا';
        this.showNotification = true
        setTimeout(() => {
          this.showNotification = false;
        }, 1700);
        return true; // Stop the function execution here
      }
    }
  },
  async created() {
  // Set isLoading to true before starting the initialization process
  this.dataLoaded = true;

  try {
    // Check if identificationNumber is stored in the sessionStorage
    if (sessionStorage.getItem('identificationNumber') == null) {
      // If not stored, set the identificationNumber from the route parameter
      this.identificationNumber = this.$route.params.BeneficiaryData.identificationNumber;
      sessionStorage.setItem('identificationNumber', this.identificationNumber);
    } else {
      // If stored, check if identificationNumber is available in the route parameter
      if (this.$route.params.BeneficiaryData && this.$route.params.BeneficiaryData.identificationNumber) {
        this.identificationNumber = this.$route.params.BeneficiaryData.identificationNumber;
        sessionStorage.setItem('identificationNumber', this.identificationNumber);
      } else {
        // If not available in the route parameter, retrieve it from the sessionStorage
        this.identificationNumber = sessionStorage.getItem('identificationNumber');
      }
    }

    // Retrieve the Meeting data based on identificationNumber
    let { data: Beneficiary } = await supabase
      .from('Beneficiary')
      .select('*')
      .eq('identificationNumber', this.identificationNumber);

    // Assign hall to component properties
    this.entityName = Beneficiary[0].entityName;
    this.entityEmail = Beneficiary[0].entityEmail;
    this.phoneNumber = "0" + Beneficiary[0].phoneNumber;
    this.beneficiaryStatus = Beneficiary[0].beneficiaryStatus;
    this.profilePicture = Beneficiary[0].profilePictureUrl; // Data property to store the profile picture file

    if (this.profilePicture) {
      const response = await fetch(this.profilePicture);
      const blob = await response.blob();

      // Create a new FormData object
      const formData = new FormData();

      // Get the filename from the profilePicture URL
      const filename = this.profilePicture.substring(this.profilePicture.lastIndexOf('/') + 1);

      // Create a new File object with the blob data
      const file = new File([blob], filename, { type: blob.type });

      // Append the file to the FormData object
      formData.append('profilePicture', file, 'image.jpg');

      // Get the input element
      const inputElement = document.getElementById('profilePicture');

      // Create a new FileList object with the file
      const filesList = new DataTransfer();
      filesList.items.add(file);
      inputElement.files = filesList.files;

      this.oldProfilePicture = this.profilePicture;
    }
  } catch (error) {
    // Handle any errors that occur during the initialization process
    console.error('An error occurred while retrieving the Beneficiary data:', error);
  } finally {
    // Set isLoading to false after the initialization process is completed
    this.dataLoaded = false;
  }
},

  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped></style>