<template>
  <div class="flex relativ">
    <menuComponent class="w-1/6"></menuComponent>
    <div  class="left-0 absolute w-5/6 px-12">
      <headerComponent></headerComponent>

     

      <div :class="{'py-10' : !this.userType}"></div>
      <!-- Start add user button -->
      <button v-if="this.userType" @click="() => $router.push('/userForm')"
        class="btn_hover py-4 w-full rounded-2xl mt-20 mb-10 bg-gradient-to-b from-[#E37B1B] to-[#ebae75] font-semibold flex justify-center items-center flex-col">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-8 h-8 mb-1">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
        </svg>
        <p>تسجيل مستخدم جديد</p>
      </button>

      <!-- End add user button -->

      <!-- Start fetch user -->
      <userFetch class=" mb-16"></userFetch>
      <!-- End fetch user -->

    </div>

  </div>
</template>

<script>
import menuComponent from '../components/menu-booking.vue'
import headerComponent from '../components/headerSection.vue'
import userFetch from '../components/Fetch-Data/userFetch.vue'
export default {
  components: {
    menuComponent,
    headerComponent,
    userFetch
  },
  data() {
  return {
    userType: ''

  };
},
  methods: {
    
  },
  mounted() {
    // this.userType = this.$store.state.userType === 'أدمن'
    this.userType = sessionStorage.getItem('UserType') == 'أدمن'
  },

}
</script>

<style lang="scss" scoped>

</style>