<template>
  <div id="app" lang="ar" dir="rtl" class=" font-custom">

    

    <div v-if="windowWidth <= 1070" class="text-xl flex items-center justify-center h-screen bg-gray-200 text-center">
      هذه المنصة مخصصة فقط لشاشات الابتوب ، الكمبيوتر الشخصي ، الايباد. نشكر لكم تفهمكم
    </div>
    <div v-else>
      <router-view/>
  </div>
  </div>


</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss">

</style>
