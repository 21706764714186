<template>
  <div>
    <div class="flex justify-between items-center mt-16">
      <div>
        <h1 v-if="signed" class="text-2xl font-bold">
          نظام الحجوزات للقاعات والاجتماعات
        </h1>
        <p class="text-[#9E9E9E]">
          ترتيب وحجز اجتماعاتك بفاعلية
        </p>
      </div>

      <!-- اذا كان المستخدم لم يسجل الدخول وليس هناك جلسة له -->
      <div
        v-if="!this.signed"
        class="cursor-pointer flex flex-row-reverse items-center gap-1 p-2"
      >
        <div
          @click="loginState"
          class="flex gap-2 items-center flex-row-reverse"
        >
          <p class="font-semibold text-gray-500">تسجيل الدخول</p>
          <svg
            class="w-8"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="#ED8526"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                  d="M10 11V8l5 4-5 4v-3H1v-2h9zm-7.542 4h2.124A8.003 8.003 0 0 0 20 12 8 8 0 0 0 4.582 9H2.458C3.732 4.943 7.522 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-4.478 0-8.268-2.943-9.542-7z"
                ></path>
              </g>
            </g>
          </svg>
        </div>

        <!--  notification section-->
        <svg
          class="w-8"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z"
              fill="#c7c7c7"
            ></path>
            <path
              d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z"
              fill="#c7c7c7"
            ></path>
          </g>
        </svg>
      </div>

      <!-- اذا كان المستخدم سجل الدخول وهناك جلسة له -->
      <div
        v-if="signed"
        class="relative cursor-pointer flex flex-row-reverse items-center gap-1 p-2"
      >
        <div @click="logout" class="flex gap-2 items-center flex-row-reverse">
          <p class="font-semibold text-gray-500">تسجيل الخروج</p>
          <svg
            class="w-8"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="#ED8526"
            transform="rotate(180)"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                  d="M10 11V8l5 4-5 4v-3H1v-2h9zm-7.542 4h2.124A8.003 8.003 0 0 0 20 12 8 8 0 0 0 4.582 9H2.458C3.732 4.943 7.522 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-4.478 0-8.268-2.943-9.542-7z"
                ></path>
              </g>
            </g>
          </svg>
        </div>

        <!--  notification section-->
        <div class="relative">
          <svg
            @click="showNotify"
            class="w-8"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z"
                fill="#c7c7c7"
              ></path>
              <path
                d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z"
                fill="#c7c7c7"
              ></path>
            </g>
          </svg>
          <div
            v-show="hasUnreadNotifications"
            class="absolute top-0 w-2 h-2 rounded-full bg-red-500"
          ></div>

          <div
            v-click-outside="handleClickOutside"
            v-if="this.notify"
            :class="{
              'h-[70px] py-2 flex justify-center items-center':
                notificationContent.length === 0,
            }"
            id="infiniteList"
            class="left-0 top-10 absolute cursor-default z-20 w-[500px] rounded-md bg-gray-100 p-2 h-[550px] overflow-y-auto custom-scrollbar"
          >
            <template v-if="notificationContent.length > 0">
              <!-- Display each notification in a div -->
              <div
                v-for="(notification, index) in notificationContent"
                :key="index"
                :class="[
                  'relative p-2 mb-2 rounded-md bg-white flex gap-2 items-center justify-start cursor-pointer', 
                  {
                    'bg-gray-200 opacity-50':
                      notification.readed_by &&
                      notification.readed_by.includes(user_name),
                    'bg-gray-300':
                      notification.readed_by &&
                      notification.readed_by.includes(user_name),
                  },
                ]"
                @click="openModalAndMarkAsRead(notification, index)"
              >
                <div
                  v-if="!notification.readed_by.includes(user_name)"
                  class="absolute w-2 h-2 bg-red-600 right-2 rounded-full"
                ></div>

                <div
                  v-if="notification.notificationType == 'newUser'"
                  class="flex gap-2 items-center justify-start"
                >
                  <div
                    :class="{
                      'mr-4': !notification.readed_by.includes(user_name),
                    }"
                  ></div>

                  <!-- content of each notification-->
                  <div class="pr-2">
                    <div class="text-lg font-bold text-red-500">
                      مستخدم جديد !
                    </div>
                    <div class="text-sm">تم اضافة مستخدم جديد</div>
                    <div class="text-sm text-gray-400">
                      {{ formatDateTime(notification.created_at) }} -
                      {{ notification.by }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="notification.notificationType == 'newInMeeting'"
                  class="flex gap-2 items-center justify-start"
                >
                  <div
                    :class="{
                      'mr-4': !notification.readed_by.includes(user_name),
                    }"
                  ></div>

                  <!-- content of each notification-->
                  <div class="pr-2">
                    <div class="text-lg font-bold text-red-500">
                      اجتماع داخلي جديد !
                    </div>
                    <div class="text-sm">تم حجز اجتماع داخلي جديد</div>
                    <div class="text-sm text-gray-400">
                      {{ formatDateTime(notification.created_at) }} -
                      {{ notification.by }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="notification.notificationType == 'newExMeeting'"
                  class="flex gap-2 items-center justify-start"
                >
                  <div
                    :class="{
                      'mr-4': !notification.readed_by.includes(user_name),
                    }"
                  ></div>

                  <!-- content of each notification-->
                  <div class="pr-2">
                    <div class="text-lg font-bold text-red-500">
                      اجتماع خارجي جديد !
                    </div>
                    <div class="text-sm">تم حجز اجتماع خارجي جديد</div>
                    <div class="text-sm text-gray-400">
                      {{ formatDateTime(notification.created_at) }} -
                      {{ notification.by }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="notification.notificationType == 'newHall'"
                  class="flex gap-2 items-center justify-start"
                >
                  <div
                    :class="{
                      'mr-4': !notification.readed_by.includes(user_name),
                    }"
                  ></div>

                  <!-- content of each notification-->
                  <div class="pr-2">
                    <div class="text-lg font-bold text-red-500">
                      قاعة جديدة !
                    </div>
                    <div class="text-sm">تم حجز قاعة جديدة</div>
                    <div class="text-sm text-gray-400">
                      {{ formatDateTime(notification.created_at) }} -
                      {{ notification.by }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="notification.notificationType == 'editMeeting'"
                  class="flex gap-2 items-center justify-start"
                >
                  <div
                    :class="{
                      'mr-4': !notification.readed_by.includes(user_name),
                    }"
                  ></div>

                  <!-- content of each notification-->
                  <div class="pr-2">
                    <div class="text-lg font-bold text-red-500">
                      تعديل اجتماع !
                    </div>
                    <div class="text-sm">تم تغير بيانات الاجتماع</div>
                    <div class="text-sm text-gray-400">
                      {{ formatDateTime(notification.created_at) }} -
                      {{ notification.by }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="notification.notificationType == 'editHall'"
                  class="flex gap-2 items-center justify-start"
                >
                  <div
                    :class="{
                      'mr-4': !notification.readed_by.includes(user_name),
                    }"
                  ></div>

                  <!-- content of each notification-->
                  <div class="pr-2">
                    <div class="text-lg font-bold text-red-500">
                      تعديل قاعة !
                    </div>
                    <div class="text-sm">تم تغير بيانات القاعة</div>
                    <div class="text-sm text-gray-400">
                      {{ formatDateTime(notification.created_at) }} -
                      {{ notification.by }}
                    </div>
                  </div>
                </div>

                <!-- button div read modal -->
                <div class="flex gap-2 justify-end flex-1">
                  <!-- we need to create a function to check the by row from the table if he is the same usre that are looged in or not -->

                  <button
                  @click="openModalAndMarkAsRead(notification, index)"
                    class="text-sm border-secondary border-2 hover:bg-secondary text-secondary hover:text-white px-2 py-1 rounded-md"
                  >
                    التفاصيل
                  </button>
                </div>
              </div>
              <!-- SVG animation for loading -->
              <div v-if="isLoading && !allNotificationsLoaded" class="text-center py-4">
                    <svg class="animate-spin h-5 w-5 mx-auto text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.003 8.003 0 0112 4V0C6.486 0 2 4.486 2 10h4v7.291zm16-9.458A8.027 8.027 0 0117.707 15H20c0-4.418-3.582-8-8-8v3.292zM7.707 9H4c0 4.418 3.582 8 8 8v-3.292A7.96 7.96 0 019 12c0-2.589 1.207-4.907 3.083-6.402L7.707 9z"></path>
                    </svg>
                </div>
                <!-- Message for no more notifications -->
                <div v-if="allNotificationsLoaded && !isLoading" class="text-center py-4">
                    <p>لا يوجد المزيد من الاشعارات</p>
                </div>
            </template>

            <template v-else>
              <!-- No notifications to display -->
              <p class="text-center">لا توجد اشعارات حاليا</p>
            </template>
          </div>
        </div>

        <div class="mx-5 font-bold text-secondary text-md cursor-default">
          <p>مرحبا بك {{ $store.getters.userName }}</p>
        </div>
      </div>
    </div>

    <!-- login section -->
    <login v-if="login"></login>
    <!-- end of login section -->

    <!-- Modal Section -->
    <modalNotification
      v-if="this.$store.state.openComponentModal"
    ></modalNotification>
    <!-- End Of Modal Section -->
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import login from "./login.vue";
import modalNotification from "./modalNotification.vue";
import { supabase } from "../../supabase";

export default {
  directives: {
    ClickOutside: vClickOutside.directive,
  },
  components: {
    login,
    modalNotification,
  },
  data() {
    return {
      signed: false, // Flag to check if the user is signed in
      notify: false, // Flag to control the display of notifications
      modalDiv: false, // Flag to control the display of modal
      login: null, // Placeholder for login component
      modalNotification: "", // Placeholder for modalNotification component
      user_name: "", // Stores the username of the authenticated user
      userRole: "", // Stores the role of the authenticated user
      logoutDisabled: "", // Flag to disable the logout button temporarily


      notifications: [], // Stores the list of notifications
            notificationContent: [], // Stores the filtered notifications based on user role
            isLoading: false, // Indicates whether new notifications are being loaded
            allNotificationsLoaded: false, // Indicates whether all notifications have been loaded
            isListRendered: false, // Indicates whether the notification list is rendered
    };
  },
  methods: {

    // Load more notifications
    async loadMore() {
    if (this.isLoading || this.allNotificationsLoaded) return;

    this.isLoading = true;

    try {
        const { data, error } = await supabase
            .from('notification')
            .select('*')
            .range(this.notifications.length, this.notifications.length + 13)
            .order('id', { ascending: false });

        if (error) {
            throw new Error(error.message);
        }

        if (data.length === 0) {
            this.allNotificationsLoaded = true;
        } else {
            let filteredNotifications = data;

            if (this.userRole === 'مشرف') {
                console.log("BB");
                filteredNotifications = data.filter(item => 
                    item.notificationType === "newExMeeting" || item.notificationType === "newInMeeting" || item.notificationType === "editMeeting"
                );
            }

            const unreadNotifications = [];
            const readNotifications = [];

            filteredNotifications.forEach(item => {
                if (item.readed_by.includes(this.user_name)) {
                    readNotifications.push(item);
                } else {
                    unreadNotifications.push(item);
                }
            });

            const newUnreadNotifications = unreadNotifications.filter(item => !this.notificationContent.map(n => n.id).includes(item.id));
            const newReadNotifications = readNotifications.filter(item => !this.notificationContent.map(n => n.id).includes(item.id));

            this.notificationContent = [...this.notificationContent, ...newUnreadNotifications, ...newReadNotifications];

            this.notifications = [...this.notifications, ...data.map(item => item.id)];
        }

       
    } catch (error) {
        console.error("Error loading notifications:", error);
    } finally {
        this.isLoading = false;
    }
},

    // Asynchronously get user information
    async getUserInfo() {
  try {
    const { data } = await supabase.auth.getUser();
    if (data && data.user && data.user.user_metadata) {
      this.user_name = data.user.user_metadata.user_name; // Set the username
      this.$store.commit('setUser_name', data.user.user_metadata.user_name);
      this.userRole = data.user.user_metadata.user_type; // Set the user role
    } else {
      console.log("No authenticated user or user metadata"); // Log if no user is authenticated or user metadata is missing
    }
  } catch (error) {
    console.error("Error retrieving user information:", error); // Log errors
  }
},

    //method display the time and date only in the notification no seconds or other things
    formatDateTime(dateTime) {
      const notificationDate = new Date(dateTime);
      const currentDate = new Date();

      // Check if the notification was created yesterday
      const isYesterday =
        notificationDate.getDate() === currentDate.getDate() - 1 &&
        notificationDate.getMonth() === currentDate.getMonth() &&
        notificationDate.getFullYear() === currentDate.getFullYear();

      // Check if the notification was created today
      const isToday =
        notificationDate.getDate() === currentDate.getDate() &&
        notificationDate.getMonth() === currentDate.getMonth() &&
        notificationDate.getFullYear() === currentDate.getFullYear();

      if (isToday) {
        // If it's today
        return "اليوم";
      } else if (isYesterday) {
        // If it's yesterday
        return "الأمس";
      } else {
        // Calculate the difference in days between notification date and current date
        const timeDifference =
          currentDate.getTime() - notificationDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

        if (daysDifference + 1 === 30) {
          // If it's 30 days ago
          return "قبل شهر";
        } else if (daysDifference + 1 === 365) {
          // If it's 365 days ago
          return "قبل سنة";
        } else {
          // Otherwise, return the number of days ago
          return `قبل ${daysDifference + 1} أيام`;
        }
      }
    },
    loginState() {
      // Dispatch action to update login state in the store
      this.$store.dispatch("updateLoginState", true);
      this.login = this.$store.state.isLoggedIn;
    },
    async logout() {
      // Check if logout button is already disabled
      if (this.logoutDisabled) {
        // If button is disabled, do nothing
        return;
      }

      try {
        // Disable the logout button to prevent multiple clicks
        this.logoutDisabled = true;

        // Perform logout action
        await supabase.auth.signOut();
        this.$store.dispatch("updateLoginState", false);
        this.$router.push("/");
        sessionStorage.clear();
        localStorage.clear();

        // Enable the logout button after a specified delay (e.g., 2 seconds)
        setTimeout(() => {
          this.logoutDisabled = false;
        }, 2000); // Adjust the delay duration as needed
      } catch (error) {
        console.error("An error occurred during logout:", error);
        // Handle errors if any

        // Ensure logout button is re-enabled in case of error
        this.logoutDisabled = false;
      }
    },
    showNotify() {
      this.notify = !this.notify;
    },
    handleClickOutside() {
      this.notify = false;
      this.modalDiv = false;
    },

    openModal(notificationType, identificationNumber) {
      // Set modalNotification to true to open the component of the modal
      this.modalNotification = this.$store.dispatch(
        "updateModalComponent",
        true
      );
      this.$store.dispatch("updateModalState", true);
      this.$store.dispatch("updateTypeModalState", notificationType);
      this.$store.dispatch("updateIDModalState", identificationNumber);
      this.notify = false;
    },
    openModalAndMarkAsRead(notification, index) {
      if (!notification.readed_by.includes(this.user_name)) {
        let updatedReadBy = [...notification.readed_by, this.user_name];
        supabase
          .from("notification")
          .update({ readed_by: updatedReadBy })
          .eq("id", notification.id)
          .then((response) => {
            if (!response.error) {
              this.$set(this.notificationContent, index, {
                ...notification,
                readed_by: updatedReadBy
              });
              this.openModal(notification.notificationType, notification.identificationNumber);
            }
          })
          .catch(error => {
            console.error("Error updating notification:", error.message);
          });
      } else {
        this.openModal(notification.notificationType, notification.identificationNumber);
      }
    },

    


  },
  computed: {
    hasUnreadNotifications() {
      // Check if there are any unread notifications for the current user
      return this.notificationContent.some((notification) => {
        return (
          !notification.readed_by ||
          !notification.readed_by.includes(this.user_name)
        );
      });
    },
    userName() {
    return this.$store.state.user_name;
  }
  },

  async mounted() {
    this.signed = JSON.parse(sessionStorage.getItem("Signed"));
    // Load notification content from localStorage
    // const storedNotifications = localStorage.getItem("notificationContent");
    // this.notificationContent = storedNotifications
    //   ? JSON.parse(storedNotifications)
    //   : [];
    if (this.signed) {
      await this.getUserInfo();
    }
    supabase
      .channel("custom-insert-channel")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "notification" },
        (payload) => {
          // Add the new notification to the beginning of the list
          this.notificationContent.unshift(payload.new);
        }
      )
      .subscribe();

    supabase
      .channel("custom-update-channel")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "notification" },
        (payload) => {
          // Find the index of the updated notification in the list
          const index = this.notificationContent.findIndex(
            (notification) => notification.id === payload.new.id
          );
          if (index !== -1) {
            // Update the notification in the list
            this.notificationContent.splice(index, 1, payload.new);
          }
        }
      )
      .subscribe();

    supabase
      .channel("custom-delete-channel")
      .on(
        "postgres_changes",
        { event: "DELETE", schema: "public", table: "notification" },
        (payload) => {
          // Find the index of the deleted notification in the list
          const index = this.notificationContent.findIndex(
            (notification) => notification.id === payload.old.id
          );
          if (index !== -1) {
            // Remove the notification from the list
            this.notificationContent.splice(index, 1);
          }
        }
      )
      .subscribe();

      this.isListRendered = true; // Set the flag to render the notification list
        this.loadMore();
  },

  updated() {
    if(this.notify){
      this.$nextTick(() => {
    const listItem = document.querySelector("#infiniteList"); // Get the scrollable list element
    listItem.addEventListener("scroll", () => {
        // Add scroll event listener to load more notifications
        if (listItem.scrollTop + listItem.clientHeight >= listItem.scrollHeight - 1) {
            this.loadMore(); // Load more notifications when the bottom is reached
        }
    });
});

      
    }
    

  },
};
</script>


<style lang="scss" scoped>
/* Add custom styles for the scroll bar */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  /* Set the width of the scroll bar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Set the background color of the scroll bar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the scroll bar thumb */
  border-radius: 4px;
  /* Set the border radius of the scroll bar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Set the color of the scroll bar thumb on hover */
}
</style>