<template>
  <!-- 
  issus:
   -->
  <div class="">
    <!-- Start notification -->
    <successNotification v-if="this.showSuccessNotification == true"></successNotification>
    <failNotification v-if="this.showFailNotification == true"></failNotification>
    <errorNotification v-if="this.showNotification == true" :NotificationMsg="NotificationMsg"></errorNotification>
    <!-- End notification -->

    <div class="flex relative">
      <menuComponent class="w-1/6"></menuComponent>
      <div class="left-0 absolute w-5/6 px-12">
        <headerComponent></headerComponent>
        <div class="mt-20 mb-10">
          <h3 class="text-3xl font-bold mb-2">تعديل بيانات المستخدم</h3>

          <div class="border rounded-lg">
            <div class="w-fit mx-auto my-16 space-y-10">
              <!-- FIRST ROW OF THE FORM -->
              <div class="flex justify-start gap-4">
                <div class="space-y-2">
                  <label class="text-[#606060]" for="userName">اسم المستخدم</label>
                  <div>
                    <input class="inputFild" type="text" name="userName" id="userName" placeholder="أدخل اسم المستخدم"
                      v-model="userName" v-validate="'required|englishWordNumberSymbol'" />
                  </div>
                  <p class="error w-[300px]">{{ errors.first("userName") }}</p>
                  <div v-if="showValidationError">
                    <p v-if="userName == '' && errors.first('userName') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class="space-y-2">
                  <label class="text-[#606060]" for="userEmail">البريد الالكتروني</label>
                  <div>
                    <input class="inputFild" type="email" name="userEmail" id="userEmail"
                      placeholder="ادخل بريدك الالكتروني" v-model="userEmail" v-validate="'required|email'" />
                  </div>
                  <p class="error">{{ errors.first("userEmail") }}</p>
                  <div v-if="showValidationError">
                    <p v-if="userEmail == '' && errors.first('userEmail') == null
      " class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF FIRST ROW OF THE FORM -->

              <!-- SECOND ROW OF THE FORM -->
              <div class="flex justify-start gap-4">
                <div class="space-y-2">
                  <label class="text-[#606060]" for="userPassword">تغيير كلمة المرور الحالية</label>
                  <div>
                    <input @input="validatePassword" class="inputFild" type="password" name="userPassword"
                      id="userPassword" placeholder="أدخل كلمة المرور  جديدة" v-model="userPassword"
                      v-validate="'required|alpha_dash|min:8'" />
                  </div>
                  <div v-if="passwordValidation.showConditions">
                    <div class="error w-[360px]">
                      {{
      passwordValidation.isValid
        ? ""
        : "يجب أن تحتوي كلمة المرور على أحرف كبيرة وصغيرة وأرقام."
    }}
                    </div>
                    <div class="error">
                      {{
        passwordValidation.startWithUpperLetter
          ? ""
          : "كلمة المرور يجب أن تبدأ بحرف كبير."
      }}
                    </div>
                    <div class="error">
                      {{
        passwordValidation.containsNumbers
          ? ""
          : "يجب ان تحتوي كلمة المرور على الاقل رقما واحدا."
      }}
                    </div>
                  </div>
                  <p class="error">{{ errors.first("userPassword") }}</p>
                  <div v-if="showValidationError">
                    <p v-if="userPassword == '' &&
      errors.first('userPassword') == null
      " class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class="space-y-2">
                  <label class="text-[#606060]" for="userType">نوع المستخدم</label>
                  <div class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-[41.6px]"
                      name="userType" id="userType" v-model="userType" v-validate="'required'">
                      <option value="مشرف">مشرف</option>
                      <option value="أدمن">أدمن</option>
                    </select>
                  </div>
                  <p class="error">{{ errors.first("userType") }}</p>
                  <div v-if="showValidationError">
                    <p v-if="userType == '' && errors.first('userType') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF SECOND ROW OF THE FORM -->

              <!-- THIRD ROW OF THE FORM -->
              <div class="flex justify-start gap-4">
                <div class="space-y-2">
                  <label class="text-[#606060]" for="jobTitle">المسمى الوظيفي</label>
                  <div>
                    <input class="inputFild" type="text" name="jobTitle" id="jobTitle" placeholder="أدخل المسمى الوظيفي"
                      v-model="jobTitle" v-validate="'required|alpha_spaces'" />
                  </div>
                  <p class="error">{{ errors.first("jobTitle") }}</p>
                  <div v-if="showValidationError">
                    <p v-if="jobTitle == '' && errors.first('jobTitle') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class="space-y-2">
                  <label class="text-[#606060]" for="userStatus">حالة المستخدم</label>
                  <div class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-[41.6px]"
                      name="userStatus" id="userStatus" v-model="userStatus" v-validate="'required'">
                      <option value="فعال" selected>فعال</option>
                      <option value="موقوف" selected>موقوف</option>
                      <option value="معلق" selected>معلق</option>
                      <!-- <option value="أدمن">أدمن</option> -->
                    </select>
                  </div>
                  <p class="error">{{ errors.first("userStatus") }}</p>
                  <div v-if="showValidationError">
                    <p v-if="userStatus == '' && errors.first('userStatus') == null
      " class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF THIRD ROW OF THE FORM -->

              <div v-if="isLoading" class="flex justify-center items-center">
                <div class="text-center">
                    <div role="status" class="flex gap-2">
                        <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class=" text-gray-500 font-semibold">جاري الإرسال</span>
                    </div>
                </div>
              </div>

              <!-- Start Button -->
              <div class="text-center">
                <button @click="submitData"
                  class="btn_hover rounded-lg bg-gradient-to-b from-[#ED8F37] to-[#ED8F37A6] px-16 py-2 text-yellow-50 text-lg">
                  حفظ
                </button>
              </div>
              <!-- End Button -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuComponent from "@/components/menu-booking.vue";
import headerComponent from "@/components/headerSection.vue";
import successNotification from "@/components/Notification/successNotification.vue";
import failNotification from "@/components/Notification/failNotification.vue";
import errorNotification from '@/components/Notification/errorNotification.vue'
import { supabase } from "../../../supabase";

export default {
  components: {
    menuComponent,
    headerComponent,
    successNotification,
    failNotification,
    errorNotification
  },
  data() {
    return {
      userName: "",
      userEmail: "",
      userPassword: "",
      userType: "",
      jobTitle: "",
      userStatus: "",
      showSuccessNotification: false,
      showFailNotification: false,
      showValidationError: false,
      passwordValidation: {
        isValid: false,
        showConditions: false,
        startWithUpperLetter: false,
        containsNumbers: false,
        //to use it in the update method .. this the old value before i enter anything in the inputs
        oldNewEmail: "",
        oldNewPassword: "",
        oldNewName: "",
        auto_id: "",
        phone: "",
      },
      showNotification: false,
      NotificationMsg: '',
      isLoading: false // icon of loading 
    };
  },
  methods: {
    async submitData() {
    if (this.userName && this.userEmail && this.userPassword && this.userType && this.jobTitle && this.userStatus &&
      !this.errors.has("userName") && !this.errors.has("userEmail") && !this.errors.has("userPassword") && !this.errors.has("jobTitle")) {

      // Set loading to true when starting the submission process
      this.isLoading = true;
      
      try {
        // Call the method to update user data in Supabase
        await this.updateUserData({
          user_name: this.userName,
          user_email: this.userEmail,
          user_password: this.userPassword,
          user_type: this.userType,
          job_title: this.jobTitle,
          user_status: this.userStatus,
          id_auto: this.auto_id,
          phone_number: this.phone,
        });

        // Show the success notification after insertion is successful
        this.showSuccessNotification = true;

        // Redirect after hiding the notification
        setTimeout(() => {
          sessionStorage.removeItem("userId");
          this.showSuccessNotification = false;
          // Redirect to the user management page after successful insertion
          this.$router.push('/userManagementPage');
        }, 1700);
      } catch (error) {
        // Handle any errors that occur during the insert process
        console.error('Error inserting data:', error.message);

        // Show the fail notification if insertion fails
        this.showFailNotification = true;

        // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
        setTimeout(() => {
          this.showFailNotification = false;
          this.showValidationError = true;
        }, 1700);
      } finally {
        // Set loading to false once the process is complete
        this.isLoading = false;
      }

    } else {
      // Show the fail notification
      this.showFailNotification = true;

      // Hide the notification after 3 seconds
      setTimeout(() => {
        this.showFailNotification = false;
        this.showValidationError = true;
      }, 3000);
      return; // Stop the function execution here
    }
  },
    async updateUserData(updatedData) {
      try {
        // Check if the updated email is different from the old one
        if (updatedData.user_email !== this.oldNewEmail) {
          const { data: existingEmailData, error: emailError } = await supabase
            .from("user_view")
            .select("*")
            .eq("email", updatedData.user_email);

          if (emailError) {
            console.error("Error fetching email data:", emailError.message);
            throw emailError;
          }

          // If there is already an entry with the updated email, show alert
          if (existingEmailData && existingEmailData.length > 0) {
            if (existingEmailData[0].id_auto !== this.auto_id) {
              // Allow updating email for the same user
              this.NotificationMsg = 'الايميل المدخل موجود مسبقا';
              console.log(this.NotificationMsg);
              if (this.NotificationMsg) {
                this.showNotification = true
              }
              setTimeout(() => {
                this.showNotification = false;
              }, 2500);
              return; // Stop the function execution here
            }
          }
        }

        // Check if the updated username is different from the old one
        if (updatedData.user_name !== this.oldNewName) {
          const { data: existingNameData, error: nameError } = await supabase
            .from("user_view")
            .select("raw_user_meta_data")
            .then((response) => {
              const filteredData = response.data.filter(
                (item) =>
                  item.raw_user_meta_data.user_name === updatedData.user_name &&
                  item.raw_user_meta_data.user_email !== this.userEmail
              );
              return { data: filteredData };
            })
            .catch((error) => {
              console.error("Error fetching username data:", error.message);
              throw error;
            });

          if (nameError) {
            console.error("Error fetching username data:", nameError.message);
            throw nameError;
          }

          // If the updated username already exists, show alert
          if (existingNameData.length > 0) {
            this.NotificationMsg = 'اسم المستخدم موجود مسبقا';
            console.log(this.NotificationMsg);
            if (this.NotificationMsg) {
              this.showNotification = true
            }
            setTimeout(() => {
              this.showNotification = false;
            }, 2500);
            return; // Stop the function execution here
          }
        }

        // Proceed with updating user data if no conflicts
        const { data, error } = await supabase.rpc("update_user0", {
          p_user_id: this.userId,
          p_email: updatedData.user_email,
          p_password: updatedData.user_password,
          p_raw_user_meta_data: updatedData,
        });

        // Check for errors during user creation
        if (error) {
          console.log(error);
          // Handle specific error codes, if any
          if (error.code == 429) {
            window.alert('لا يمكن اضافة مستخدم بالوقت الحالي الرجاء المحاولة مرة اخرى في وقت لاحق');
          }
          return;


        } else {
          console.log('User data inserted successfully:', data);
          this.showSuccessNotification = true;
        }

        // Show the success notification after insertion is successful
        this.showSuccessNotification = true;

        // Redirect after hiding the notification
        setTimeout(() => {
          sessionStorage.removeItem("userId");
          this.showSuccessNotification = false;
          console.log("User data updated successfully:", data);
          // Redirect to the user management page after successful insertion
          this.$router.push('/userManagementPage');
        }, 1700);

      } catch (error) {
        // Handle any errors that occur during the insert process
        console.error('Error inserting data:', error.message);

        // Show the fail notification if insertion fails
        this.showFailNotification = true;

        // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
        setTimeout(() => {
          this.showFailNotification = false;
          this.showValidationError = true;
        }, 1700);
      }
    },
    validatePassword() {
      this.passwordValidation.showConditions = true;
      const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/;
      this.passwordValidation.isValid = pattern.test(this.userPassword);
      this.passwordValidation.startWithUpperLetter = /^[A-Z]/.test(
        this.userPassword
      );
      this.passwordValidation.containsNumbers = /\d/.test(this.userPassword);
      if (
        this.passwordValidation.isValid &&
        this.passwordValidation.startWithUpperLetter &&
        this.passwordValidation.containsNumbers
      ) {
        this.passwordValidation.showConditions = false;
      }
    },
    async fetchUserViewData() {
      // this.userId = this.$route.params.userId;
      if (!this.userId) {
        console.error("User ID is missing or undefined.");
        return;
      }
      const { data, error } = await supabase
        .from("user_view")
        .select()
        .eq("id", this.userId);
      if (error) {
        console.error("Error fetching user_view data:", error.message);
      } else {
        const userData = data[0].raw_user_meta_data;
        this.userName = userData.user_name || ""; // Placeholder for userName error
        this.userEmail = userData.user_email || ""; // Placeholder for userEmail error
        this.userPassword = userData.user_password || ""; // Placeholder for userPassword error
        this.userType = userData.user_type || ""; // Placeholder for userType error
        this.jobTitle = userData.job_title || ""; // Placeholder for jobTitle error
        this.userStatus = userData.user_status || "";
        this.oldNewEmail = userData.user_email || ""; // to check if the old and new placeholder is the same if it was the same it will not go to update email
        this.oldNewPassword = userData.user_password || "";
        this.oldNewName = userData.user_name || '';
        this.auto_id = userData.id_auto;
        this.phone = userData.phone_number;
      }
    },
  },
  created() {
    // Check if userId is stored in the sessionStorage
    if (sessionStorage.getItem("userId") == null) {
      // If not stored, set the userId from the route parameter
      this.userId = this.$route.params.userId;
      sessionStorage.setItem("userId", this.userId);
    } else {
      // If stored, check if userId is available in the route parameter
      if (this.$route.params.userId) {
        this.userId = this.$route.params.userId;
        sessionStorage.setItem("userId", this.userId);
      } else {
        // If not available in the route parameter, retrieve it from the sessionStorage
        this.userId = sessionStorage.getItem("userId");
      }
    }
    this.fetchUserViewData();
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped></style>
