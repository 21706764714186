<template>
  <!-- 
  issus:
   -->
  <div>

    <!-- Start notification -->
    <successNotification v-if="this.showSuccessNotification == true"></successNotification>
    <failNotification v-if="this.showFailNotification == true"></failNotification>
    <errorNotification v-if="this.showNotification == true" :NotificationMsg="NotificationMsg"></errorNotification>
    <!-- End notification -->

    <div class="flex relative">
      <menuComponent class="w-1/6"></menuComponent>
      <div class="left-0 absolute w-5/6  px-12">
        <headerComponent></headerComponent>
        <div class="mt-20 mb-10">

          <div class=" mt-20 mb-10">
            <h3 class="text-3xl font-bold mb-2">إضافة مستفيد جديد</h3>
            <div class="border rounded-lg">
              <div class=" w-fit mx-auto my-16 space-y-10">

                <!-- FIRST ROW OF THE FORM -->
                <div class="flex justify-start gap-4">

                  <div class=" space-y-2">
                    <label class="text-[#606060]" for="entityName">اسم الجهة</label>
                    <div>
                      <input class="inputFild" type="text" name="entityName" id="entityName"
                        placeholder="أدخل اسم الجهة" v-model="entityName" v-validate="'required|alpha_spaces'">
                    </div>
                    <p class="error">{{ errors.first('entityName') }}</p>
                    <div v-if="showValidationError">
                      <p v-if="entityName == '' && errors.first('entityName') == null" class="error">
                        يرجى تعبئة الحقل
                      </p>
                    </div>
                  </div>

                  <div class=" space-y-2">
                    <label class="text-[#606060]" for="entityEmail">البريد الالكتروني</label>
                    <div>
                      <input class="inputFild" type="email" name="entityEmail" id="entityEmail"
                        placeholder="ادخل بريدك الالكتروني" v-model="entityEmail" v-validate="'required|email'">
                    </div>
                    <p class="error">{{ errors.first('entityEmail') }}</p>
                    <div v-if="showValidationError">
                      <p v-if="entityEmail == '' && errors.first('entityEmail') == null" class="error">
                        يرجى تعبئة الحقل
                      </p>
                    </div>
                  </div>

                </div>
                <!-- END OF FIRST ROW OF THE FORM -->

              
                <!-- SECOND ROW OF THE FORM -->
                <div class="flex justify-start gap-4">

                  <div class=" space-y-2">
                    <label class="text-[#606060]" for="phoneNumber">رقم الجوال</label>
                    <div>
                      <input class="inputFild" type="text" name="phoneNumber" id="phoneNumber" placeholder="05"
                        v-model="phoneNumber" v-validate="'required|numeric|min:10|max:10'">
                    </div>
                    <p class="error">{{ errors.first('phoneNumber') }}</p>
                    <div v-if="showValidationError">
                      <p v-if="phoneNumber == '' && errors.first('phoneNumber') == null" class="error">
                        يرجى تعبئة الحقل
                      </p>
                    </div>
                  </div>

                  <!-- Profile Picture Row -->
                <div class="flex justify-start gap-4">
                <div class="space-y-2">
                  <label class="text-secondary" for="profilePicture">شعار المستفيد</label>
                  <div>
                    <input class="inputField" type="file" accept="image/*" name="profilePicture" id="profilePicture" @change="handleFileUpload">
                  </div>
                  <div v-if="!showValidationError && fileError" >
                    <p class="error">{{ fileError }}</p>
                  </div>
                  <div v-if="showValidationError">
                    <p v-if="fileError" class="error">
                      {{ this.fileError }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF Profile Picture Row -->

                </div>
                <!-- END OF SECOND ROW OF THE FORM -->
                <div v-if="isLoading" class="flex justify-center items-center">
                <div class="text-center">
                    <div role="status" class="flex gap-2">
                        <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class=" text-gray-500 font-semibold">جاري الإرسال</span>
                    </div>
                </div>
              </div>

                <!-- Start Button -->
                <div class=" text-center">
                  <button @click="submitData"
                    class="btn_hover rounded-lg bg-gradient-to-b from-[#ED8F37] to-[#ED8F37A6] px-16 py-2 text-yellow-50 text-lg">إضافة</button>
                </div>
                <!-- End Button -->

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import menuComponent from '@/components/menu-booking.vue'
import headerComponent from '@/components/headerSection.vue'
import successNotification from '@/components/Notification/successNotification.vue'
import failNotification from '@/components/Notification/failNotification.vue'
import errorNotification from '@/components/Notification/errorNotification.vue'
import { supabase } from '../../../supabase';

export default {
  name: 'beneficiaryForm',
  components: {
    menuComponent,
    headerComponent,
    successNotification,
    failNotification,
    errorNotification
  },
  data() {
    return {
      identificationNumber: '',
      entityName: '',
      entityEmail: '',
      phoneNumber: '',
      showSuccessNotification: false,
      showFailNotification: false,
      showValidationError: false,
      showNotification: false,
      NotificationMsg: '',

      
      profilePicture: null, // Data property to store the profile picture file
      fileError: '', // Data property to store file validation errors
      isLoading: false // icon of loading 
    }
  },
  methods: {
    handleFileUpload(event) {
  const file = event.target.files[0];

  // Clear previous file error message
  this.fileError = '';
  
  // Check file size (2MB limit)
  if (file && file.size > 2 * 1024 * 1024) {
    this.fileError = 'حجم الملف يجب ان يكون أقل من 2 ميجا بايت.';
    return; // Stop further processing
  }

  // Check image dimensions
  const image = new Image();
  image.onload = () => {
    if (image.width !== 250 || image.height !== 250) {
      this.fileError = 'يجب أن يكون حجم الصورة 250x250 بيكسل.';
    } else {
      this.profilePicture = file;
      this.fileError = '';
    }
  };
  image.src = URL.createObjectURL(file);
},
    async uploadProfilePicture() {
  if (!this.profilePicture) return null;

  // Encode the filename
  const encodedFileName = encodeURIComponent(new Date().toISOString().replace(/[:.-]/g, ''));

  const { error } = await supabase.storage
    .from('profile')
    .upload(`image/${encodedFileName}`, this.profilePicture);

  if (error) {
    console.error('Error uploading file:', error);
    throw error;
  }

  try {
    const publicURL = await supabase.storage
  .from('profile')
  .getPublicUrl(`image/${encodedFileName}`);

    return publicURL.data.publicUrl;
  } catch (error) {
    console.error('Error getting public URL:', error);
    throw error;
  }
},
    // Function to submit Beneficiary data
    async submitData() {
    this.isLoading = true; // Set isLoading to true at the start of the submission process

    // Check if all fields are filled and there are no validation errors
    if (this.entityName && this.entityEmail && this.phoneNumber && !this.fileError &&
      !this.errors.has('entityName') && !this.errors.has('entityEmail') && !this.errors.has('phoneNumber')) {
      
      // Call the asynchronous function checkIfDuplicates to determine if there are any duplicate entries in the database.
      const isDuplicates = await this.checkIfDuplicates();
      // If duplicates are found (isDuplicates is true), return from the function without further processing.
      if (isDuplicates) {
        this.isLoading = false; // Set isLoading to false if duplicates are found
        return;
      }

      // Proceed with insertion if no entry with the same email or name exists
      try {
        const profilePictureUrl = await this.uploadProfilePicture();

        // Insert the Beneficiary data into the database
        await supabase
          .from('Beneficiary')
          .insert([{ entityName: this.entityName, entityEmail: this.entityEmail, phoneNumber: this.phoneNumber, beneficiaryStatus: 'معلق', creatingDate: new Date().toISOString().split('T')[0] , profilePictureUrl: profilePictureUrl }]);

        // Show the success notification after insertion is successful
        this.showSuccessNotification = true;

        // Redirect after hiding the notification
        setTimeout(() => {
          this.showSuccessNotification = false;
          this.$router.push('/beneficiaryManagementPage');
          this.isLoading = false; // Set isLoading to false after successful submission
        }, 1700);

      } catch (error) {
        // Handle any errors that occur during the insert process
        console.error('Error inserting data:', error.message);

        // Show the fail notification if insertion fails
        this.showFailNotification = true;

        // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
        setTimeout(() => {
          this.showFailNotification = false;
          this.showValidationError = true;
          this.isLoading = false; // Set isLoading to false if there is an error during submission
        }, 1700);
      }

    } else {
      // Show the fail notification if any field is empty or there are validation errors
      this.showFailNotification = true;

      // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
      setTimeout(() => {
        this.showFailNotification = false;
        this.showValidationError = true;
        this.isLoading = false; // Set isLoading to false if there are validation errors
      }, 1700);
    }
  },
    // Function to check for duplicate entries in the database
    async checkIfDuplicates() {

      // This section defines two asynchronous functions. 
      // The first function, submitData, submits meeting data to a database after performing validation checks and handling duplicate entries. It shows success or failure notifications accordingly.
      // The second function, checkIfDuplicates, checks for duplicate entries in the database based on entityName and entityEmail fields. It handles errors and shows alerts if duplicates are found.

      // Check if name already exists
      const { data: existingNameData, error: nameError } = await supabase
        .from('Beneficiary')
        .select('*')
        .eq('entityName', this.entityName);
      if (nameError) {
        console.error('Error fetching name data:', nameError.message);
        return true; // Stop the function execution here
      }

      // Check if email already exists
      const { data: existingEmailData, error: emailError } = await supabase
        .from('Beneficiary')
        .select('*')
        .eq('entityEmail', this.entityEmail);
      if (emailError) {
        console.error('Error fetching email data:', emailError.message);
        return true; // Stop the function execution here
      }

      // If there is already an entry with the same name, show alert
      if ((existingNameData && existingNameData.length > 0)) {
        this.NotificationMsg = 'الجهة المدخلة موجودة مسبقا';
        this.showNotification = true
        setTimeout(() => {
          this.showNotification = false;
        }, 1700);
        return true; // Stop the function execution here
      }

      // If there is already an entry with the same email, show alert
      if ((existingEmailData && existingEmailData.length > 0)) {
        this.NotificationMsg = 'الايميل المدخل موجود مسبقا';
        this.showNotification = true
        setTimeout(() => {
          this.showNotification = false;
        }, 1700);
        return true; // Stop the function execution here
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>
