<template>
  <div>
    <!-- Start notification -->
    <successNotification v-if="this.showSuccessNotification"></successNotification>
    <failNotification v-if="this.showFailNotification"></failNotification>
    <!-- Modal Alert -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <p>اليوم المحدد غير متاح للحجز، الرجاء اختيار يوم آخر.</p>
        <button class="bg-[#DA4403] p-1 m-2 rounded-md text-zinc-50" @click="closeModal">إغلاق</button>
      </div>
    </div>
    <!-- End Modal -->

    <div class="flex relative">
      <menuComponent class="w-1/6"></menuComponent>
      <div class="left-0 absolute w-5/6 px-12">
        <headerComponent></headerComponent>
        <div class="mt-20 mb-10">
          <h3 class="text-3xl font-bold mb-2">حجز اجتماع جديد داخلي</h3>

          <div v-if="dataLoaded">
            <div v-if="entitys.length > 0 && meetingRooms.length > 0" class="border rounded-lg">
              <div class="w-fit mx-auto my-16 space-y-10">

                <!-- FIRST ROW OF THE FORM -->
                <div class="flex justify-start gap-4">
                  <div class="space-y-2">
                    <label class="text-secondary" for="entityType">نوع الجهة</label>
                    <div class="w-[360px]">
                      <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10" name="entityType" id="entityType" v-model="entityType">
                        <option value="داخلي">داخلي</option>
                      </select>
                    </div>
                  </div>

                  <div class="space-y-2">
                    <label class="text-secondary" for="entityName">اختر الجهة</label>
                    <div class="w-[360px]">
                      <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10" name="entityName" id="entityName" v-model="entityName">
                        <option v-for="(entity, index) in entitys" :key="index" :value="entity">{{ entity }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- END OF FIRST ROW OF THE FORM -->

                <!-- SECOND ROW OF THE FORM -->
                <div class="flex justify-start gap-4">
                  <div class="space-y-2">
                    <label class="text-secondary" for="meetingRoom">اختر القاعة</label>
                    <div class="w-[360px]">
                      <select @change="timeRange(); available_hours();" class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10" name="meetingRoom" id="meetingRoom" v-model="meetingRoom">
                        <option v-for="(room, index) in meetingRooms" :key="index" :value="room">{{ room }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="space-y-2">
                    <label class="text-secondary" for="meetingDate">تاريخ الاجتماع</label>
                    <div>
                      <input @change="checkUnavailableDates" @input="disableSelectedDays" class="inputFild" type="date" name="meetingDate" id="meetingDate" placeholder="حدد تاريخ الاجتماع" v-model="meetingDate" :min="minDate" v-validate="'required'">
                    </div>
                    <p class="error">{{ errors.first('meetingDate') }}</p>
                    <div v-if="showValidationError">
                      <p v-if="meetingDate == '' && errors.first('meetingDate') == null" class="error">
                        يرجى تعبئة الحقل
                      </p>
                    </div>
                  </div>
                </div>
                <!-- END OF SECOND ROW OF THE FORM -->

                <!-- THIRD ROW OF THE FORM -->
                <div class="flex justify-start gap-4">
                  <div class="space-y-2">
                    <label class="text-secondary" for="meeting_duration">اختر مدة الاجتماع</label>
                    <div class="w-[360px]">
                      <select :disabled="disableLastInputs" @change="deleteStartTime" @click="removePastTimes"
                        class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10"
                        name="meeting_duration" id="meeting_duration" v-model="meeting_duration" v-validate="'required'">
                        <option v-for="(d) in duration" :key="d.value" :value="d.value">{{ d.time }}
                        </option>
                      </select>
                    </div>
                    <p class="error">{{ errors.first('meeting_duration') }}</p>
                    <div v-if="showValidationError">
                      <p v-if="meeting_duration == '' && errors.first('meeting_duration') == null" class="error">
                        يرجى تعبئة الحقل
                      </p>
                    </div>
                  </div>

                  <div class="space-y-2">
                    <label class="text-secondary" for="startTime">اختر وقت بداية الاجتماع</label>
                    <div class="w-[360px]">
                      <select @focus="filteredStartTimes" @change="filteredEndTimes" :disabled="disableIfNoDuration"
                        class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10" name="startTime"
                        id="startTime" v-model="startTime" v-validate="'required'">
                        <option v-for="(time, index) in filteredTimes" :key="index" :value="time"
                          style="direction: ltr; text-align: right;">{{ time }}
                        </option>
                      </select>
                    </div>
                    <p class="error">{{ errors.first('startTime') }}</p>
                    <div v-if="showValidationError">
                      <p v-if="startTime == '' && errors.first('startTime') == null" class="error">
                        يرجى تعبئة الحقل
                      </p>
                    </div>
                  </div>
                </div>
                <!-- END OF THIRD ROW OF THE FORM -->

                <!-- Fourth ROW OF THE FORM -->
                <div class="flex justify-center gap-4">
                  <div class="space-y-2">
                    <label class="text-secondary" for="meetingPpl">أعضاء الاجتماع:</label>
                    <div class="w-[360px]">
                      <textarea type="text" class="inputFild" name="meetingPpl" id="meetingPpl" placeholder="اسماء أعضاء الاجتماع"
                        v-model="meetingPpl">
                      </textarea>
                    </div>
                  </div>
                </div>
                <!-- END OF Fourth ROW OF THE FORM -->

                <!-- Display Meeting Time -->
                <div class="flex justify-center gap-4">
                  <div class="space-y-2">
                    <p v-if="this.startTime && this.endTime">بداية وقت الاجتماع الساعة {{ this.startTime }} وينتهي
                      الساعة {{ this.endTime }}</p>
                  </div>
                </div>
                <!-- End of Meeting Time Display -->

                <!-- Loading Indicator -->
                <div v-if="isLoading" class="flex justify-center items-center">
                  <div class="text-center">
                      <div role="status" class="flex gap-2">
                          <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                          </svg>
                          <span class=" text-gray-500 font-semibold">جاري الإرسال</span>
                      </div>
                  </div>
                </div>

                <!-- Start Button -->
                <div class="text-center">
                  <button @click="submitData"
                    class="btn_hover rounded-lg bg-gradient-to-b from-[#ED8F37] to-[#ED8F37A6] px-16 py-2 text-yellow-50 text-lg" :disabled="!isDateAllowed">إضافة</button>
                </div>
                <!-- End Button -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuComponent from '@/components/menu-booking.vue'
import headerComponent from '@/components/headerSection.vue'
import successNotification from '@/components/Notification/successNotification.vue'
import failNotification from '@/components/Notification/failNotification.vue'
import filter from '@/mixins/filter.js'
import { supabase } from '../../../supabase';

export default {
  name: 'internalBooking',
  components: {
    menuComponent,
    headerComponent,
    successNotification,
    failNotification,
  },
  data() {
    return {
      identificationNumber: '',
      entityType: 'داخلي',
      entitys: [],
      entityName: '',
      meetingRooms: [],
      meetingRoom: '',
      timeArray: [],
      filteredTimes: [],
      meetingDate: '',
      unavailableDates: [],
      duration: [],
      meeting_duration: '',
      startTime: '',
      endTime: '',
      user_name: '',
      userRole: '',
      dataLoaded: false,
      showSuccessNotification: false,
      showFailNotification: false,
      showValidationError: false,
      isLoading: false,
      meetingPpl: '',
      disabledDays: [5], // 5 represents Friday
      alertShown: false,
      isDateAllowed: true, // للتحقق من صلاحية التاريخ
      showModal: false, // عرض المودال للتنبيه
    }
  },
  watch: {
    meetingDate(newDate) {
      const selectedDate = new Date(newDate);
      const day = selectedDate.getDay();

      if (this.disabledDays.includes(day)) {
        if (!this.alertShown) {
          this.alertShown = true;
          this.isDateAllowed = false;

          // عرض الـ modal
          this.showModal = true;
          this.$nextTick(() => {
            this.meetingDate = ''; 
            this.alertShown = false;
          });
        }
      } else {
        this.isDateAllowed = true;
        this.alertShown = false;
      }
    }
  },
  methods: {
    closeModal() {
      this.showModal = false; // إغلاق المودال
    },
    async submitData() {
      if (this.entityType && this.entityName && this.meetingRoom && this.meetingDate && this.meeting_duration && this.startTime && this.endTime) {
        this.isLoading = true;

        try {
          let { data: Beneficiary } = await supabase
            .from('Beneficiary')
            .select('phoneNumber')
            .eq('entityName', this.entityName);
          const matchingBeneficiary = Beneficiary.map(Beneficiary => Beneficiary.phoneNumber);

          const { data } = await supabase
            .from('Meeting')
            .insert([{ reservationHolderName: this.entityName, entityName: this.entityName, entityType: this.entityType, phoneNumber: matchingBeneficiary[0], meetingRoom: this.meetingRoom, meetingDate: this.meetingDate, meeting_duration: this.meeting_duration, startTime: this.startTime, endTime: this.endTime, meetingStatus: 'معلق', meetingPpl: this.meetingPpl }])
            .select();

          this.showSuccessNotification = true;
          setTimeout(() => {
            this.showSuccessNotification = false;
            this.identificationNumber = data[0].identificationNumber;
            this.insertNotification();
            this.$router.push('/meetingManagementPage');
          }, 1700);

        } catch (error) {
          console.error('Error inserting data:', error.message);
          this.showFailNotification = true;
          setTimeout(() => {
            this.showFailNotification = false;
            this.showValidationError = true;
          }, 1700);

        } finally {
          this.isLoading = false;
        }
      } else {
        this.showFailNotification = true;
        setTimeout(() => {
          this.showFailNotification = false;
          this.showValidationError = true;
        }, 1700);
        return;
      }
    },
    async insertNotification() {
      const notification = {
        by: sessionStorage.getItem('userName'),
        notificationType: 'newInMeeting',
        identificationNumber: this.identificationNumber,
        readed_by: []
      };

      await supabase
        .from('notification')
        .insert([notification])
        .then(result => {
          if (result.error) {
            console.error('Failed to send notification:', result.error);
          }
        }).catch(error => {
          console.error('Error sending notification:', error);
        });
    },
    async getUserInfo() {
      try {
        const { data } = await supabase.auth.getUser();
        if (data) {
          this.user_name = data.user.user_metadata.user_name;
          this.userRole = data.user.user_metadata.user_type;
        } else {
          console.log("No authenticated user");
        }
      } catch (error) {
        console.error("Error retrieving user information:", error);
      }
    }
  },
  computed: {
    disableLastInputs() {
      return !(this.entityType && this.entityName && this.meetingRoom && this.meetingDate);
    },
    disableIfNoDuration() {
      return !this.meeting_duration;
    },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
  mixins: [filter],
  async mounted() {
    let { data: Beneficiary } = await supabase
      .from('Beneficiary')
      .select('entityName, beneficiaryStatus');
    this.entitys.push(...Beneficiary.filter(e => e.beneficiaryStatus === 'فعال').map(e => e.entityName));

    let { data: Hall } = await supabase
      .from('Hall')
      .select('hallName, hallStatus');
    this.meetingRooms.push(...Hall.filter(e => e.hallStatus === 'فعال').map(e => e.hallName));

    await this.getUserInfo();
    this.dataLoaded = true;
  },
}
</script>

<style scoped>
/* تنسيق الـ modal */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
</style>
