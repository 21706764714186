<template>
  <div>

    <!-- fillter -->
    <div class=" w-full border-[1px] border-[#D5D5D5] rounded-lg bg-[#F9F9FB] flex items-center h-[63.2px] mb-6">
      <div class="border-l-[1px] p-4 grow">
        <div class="h-[32.2px] flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
          </svg>
        </div>
      </div>
      <div class="border-l-[1px] p-4 w-72 text-center h-full flex justify-center items-center">
        <p>فرز بواسطة</p>
      </div>
      <div class="border-l-[1px] p-4 w-72 text-center">
        <input class=" w-fit bg-transparent focus:outline-none cursor-pointer" type="Date" name="registrationDate"
          id="registrationDate" v-model="registrationDate">
      </div>
      <div class="border-l-[1px] p-4 w-72 text-center">
        <select class="w-fit bg-transparent focus:outline-none cursor-pointer" name="hallStatus" id="hallStatus"
          v-model="hallStatus">
          <option value="حالة المستخدم" selected>حالة القاعة</option>
          <option value="فعال">فعال</option>
          <option value="موقوف">موقوف</option>
          <option value="معلق">معلق</option>
        </select>
      </div>
      <div @click="reset" class="flex justify-center items-center gap-5 p-4 w-60 cursor-pointer">
        <div>
          <p class=" text-red-600">إعادة التهيئة</p>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6 text-red-600">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
          </svg>

        </div>
      </div>
    </div>
    <!-- fillter -->

    <!-- content tabel -->
    <div class="mb-6">
      <!-- Header -->
      <div :class="{'grid-cols-7': userType, 'grid-cols-6': !userType}"
        class="w-full border-[1px] border-[#D5D5D5] rounded-t-lg bg-[#F9F9FB] grid  items-center text-center py-4 font-bold">
        <div>
          <p>رقم التعريف</p>
        </div>
        <div>
          <p>اسم القاعة</p>
        </div>
        <div>
          <p>عدد الأشخاص المتاح</p>
        </div>
        <div>
          <p>وقت بداية العمل</p>
        </div>
        <div>
          <p>وقت نهاية العمل</p>
        </div>
        <div>
          <p>حالة القاعة</p>
        </div>
        <div v-if="this.userType">
          <p>إعدادات</p>
        </div>
      </div>
      <!-- content -->
      <div 
        class="w-full border-l border-r border-b border-[1px] border-[#D5D5D5] rounded-b-lg text-center py-4 space-y-8">

        <!-- THE LOADING ICON WHILE DATA NOT COMPLETE FETCHING -->
        <div v-if="isLoading" class="flex justify-center items-center">
                <div class="text-center">
                    <div role="status" class="flex gap-2">
                        <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <!-- <span v-if="isLoading" class=" text-gray-500 font-semibold">جاري الإرسال</span> -->
                    </div>
                </div>
        </div>
        <!-- END OF THE LOADING ICON WHILE DATA NOT COMPLETE FETCHING -->


        <div :class="{'grid-cols-7': userType, 'grid-cols-6': !userType}" class="grid grid-cols-6 items-center" v-for="data in filteredData" :key="data.iIdentificationNumber">
          <div>
            <p>{{ data.identificationNumber.toString().padStart(6, '0') }}</p>
          </div>
          <div>
            <p class=" font-bold">{{ data.hallName }}</p>
          </div>
          <div>
            <p>{{ data.pplNumber }}</p>
          </div>
          <div>
            <p dir="ltr">{{ data.startTime }}</p>
          </div>
          <div>
            <p dir="ltr">{{ data.endTime }}</p>
          </div>
          <div>
            <p :class="{
            'w-[105px] mx-auto py-2 px-6 rounded-lg bg-opacity-20': true,
            'bg-[#00B69B] text-[#00B69B]': data.hallStatus == 'فعال',
            'bg-[#EA0234] text-[#EA0234]': data.hallStatus == 'موقوف',
            'bg-[#AEAEAE] text-[#AEAEAE]': data.hallStatus == 'معلق'
          }">
              {{ data.hallStatus }}
            </p>
          </div>
          <div>
            <div v-if="userType" @click="navigateToEditHall(data)"
              class=" w-fit mx-auto py-1 px-8 border-[1px] border-[#D5D5D5] rounded-lg bg-[#F9F9FB] text-primary cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
              </svg>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- content tabel -->

  </div>
</template>

<script>
import { supabase } from '../../../supabase';

export default {
  data() {
    return {
      registrationDate: '',
      hallStatus: 'حالة المستخدم',
      datas: [],
      userType: '',
      isLoading: false, // icon of loading 
    }
  },
  methods: {
    reset() {
      this.hallStatus = 'حالة المستخدم',
      this.registrationDate = null
    },
    matchesDate(data) {
      if (!this.registrationDate) {
        return true; // No date selected, so all dates are considered a match
      }
      const selectedDate = new Date(this.registrationDate).toISOString().split('T')[0];
      return data.creatingDate === selectedDate;
    },
    matchesUserStatus(data) {
      if (this.hallStatus === 'حالة المستخدم') {
        return true; // No specific user status selected, so all statuses are considered a match
      }
      return data.hallStatus === this.hallStatus;
    },
    navigateToEditHall(data) {
      this.$router.push({
        name: 'editeHall',
        params: {
          hallData: data
        }
      });
    }
  },
  computed: {
    filteredData() {
      return this.datas.filter(data => this.matchesDate(data) && this.matchesUserStatus(data))
    }
  },
  async mounted() {
  // Set isLoading to true before starting the data fetching process
  this.isLoading = true;

  this.userType = sessionStorage.getItem('UserType') == 'أدمن'

  // fetched data from supabase
  try {
    const { data: Hall, error } = await supabase
      .from('Hall')
      .select('*')
      .order('identificationNumber', { ascending: true });

    if (error) {
      // Handle the error, such as displaying an error message
      console.error('Error fetching Beneficiary data:', error);
    } else {
      // Assign the fetched data to the component's `datas` property
      this.datas = Hall;
    }
  } catch (error) {
    // Handle any errors that occur during the data fetching process
    console.error('An error occurred while fetching Beneficiary data:', error);
  } finally {
    // Set isLoading to false after the data fetching process is completed
    this.isLoading = false;
  }
}


}
</script>

<style lang="scss" scoped></style>