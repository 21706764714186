import { supabase } from '../../supabase';
import moment from 'moment'; // Import Moment.js library at the top

export default {
  methods: {
    // Asynchronous function to calculate available hours
    async available_hours() {
      // Reset meeting duration
      this.meeting_duration = '';
    
      // Retrieve the available hours for the specified meeting room from the 'Hall' table
      let { data: Hall } = await supabase
        .from('Hall')
        .select('available_hours')
        .eq('hallName', this.meetingRoom);
    
      const availableHours = Hall.map(hall => hall.available_hours);
    
      // Clear the duration array
      this.duration.length = 0;
    
      // Check if the user type is 'مشرف' (supervisor)
      const isSupervisor = await sessionStorage.getItem('UserType') == 'مشرف';

      console.log(isSupervisor);
    
      // If the user is a supervisor, set the durations to 1 and 2 hours only
      if (isSupervisor) {
        this.duration.push({ time: 'ساعة', value: 1 });
        this.duration.push({ time: 'ساعتان', value: 2 });
      } else {
        // Iterate over the available hours and add corresponding duration options for full hours only
        for (let i = 1; i <= availableHours[0]; i++) {
          if (i === 1) {
            this.duration.push({ time: 'ساعة', value: i });
          } else if (i === 2) {
            this.duration.push({ time: 'ساعتان', value: i });
          } else if (i === 3) {
            this.duration.push({ time: 'ثلاث ساعات', value: i });
          } else if (i === 4) {
            this.duration.push({ time: 'أربع ساعات', value: i });
          }
        }
      }
    },
    deleteStartTime() {
      this.startTime = "";
    },
    // Asynchronous function to calculate time range
    async timeRange() {
      // Retrieve the start times for the specified meeting room from the 'Hall' table
      let { data: startTime } = await supabase
        .from('Hall')
        .select('startTime')
        .eq('hallName', this.meetingRoom);
      const start = startTime.map(hall => hall.startTime);

      // Retrieve the end times for the specified meeting room from the 'Hall' table
      let { data: endTime } = await supabase
        .from('Hall')
        .select('endTime')
        .eq('hallName', this.meetingRoom);
      const end = endTime.map(hall => hall.endTime);

      // Convert start and end times to Moment objects
      const startMoment = moment(start, 'h:mm A');
      const endMoment = moment(end, 'h:mm A');

      // Increment the time by 60 minutes until it reaches the end time
      this.timeArray.length = 0;
      let currentTime = moment(startMoment);
      while (currentTime.isSameOrBefore(endMoment)) {
        this.timeArray.push(currentTime.format('h:mm A'));
        currentTime.add(60, 'minutes');
      }

      // Remove past times based on the current time
      this.removePastTimes();
    },
    // Asynchronous function to check unavailable dates
    async checkUnavailableDates() {
      // Query the database to get unavailable dates for the specified meeting date and status
      let { data: unavailableDates } = await supabase
        .from('Meeting')
        .select('*')
        .eq('meetingDate', this.meetingDate)
        .in('meetingStatus', ['محجوز', 'معلق', 'الاجتماع جاري']);

      // Update the unavailableDates array with the fetched data
      this.unavailableDates = unavailableDates;
      // Reset the start time variable
      this.startTime = '';
    },
    async filteredStartTimes() {
      // Filter out unavailable dates that match the selected meeting room
      const unavailableDates = this.unavailableDates.filter(e => e.meetingRoom === this.meetingRoom);
    
      // Filter `timeArray` based on the duration of the meeting
      this.filteredTimes = this.timeArray.filter((time) => {
        const startMoment = moment(time, 'h:mm A');
        const endMoment = startMoment.clone().add(this.meeting_duration, 'hours');
    
        // Ensure the time slot does not overlap with any unavailable booking
        return !unavailableDates.some(unavailableDate => {
          const bookedStart = moment(unavailableDate.startTime, 'h:mm A');
          const bookedEnd = moment(unavailableDate.endTime, 'h:mm A');
    
          // Allow time slots that end before the booked start or start after the booked end
          return endMoment.isAfter(bookedStart) && startMoment.isBefore(bookedEnd);
        });
      });
    
      // Remove past times based on the current time
      this.removePastTimes();
    },
    // Function to calculate filtered end times using Moment.js library
    filteredEndTimes() {
      // Calculate end time by adding meeting duration to start time and formatting the result in 'h:mm A' format
      this.endTime = moment(this.startTime, 'h:mm A').add(this.meeting_duration * 60, 'minutes').format('h:mm A'); // multiply by 60 to get minutes
    },
    removePastTimes() {
      // Get the current date in ISO format
      const currentDate = new Date().toISOString().split('T')[0];
    
      // Get the current time
      const currentTime = moment();
    
      // If meeting date is today, remove hours before the current time
      if (this.meetingDate === currentDate) {
        // Filter out times before the current time, excluding those within 30 minutes
        this.timeArray = this.timeArray.filter(time => {
          const timeMoment = moment(time, 'h:mm A');
          const currentTimeMinus30 = currentTime.clone().subtract(30, 'minutes');
          return timeMoment.isSameOrAfter(currentTimeMinus30, 'minute');
        });
      }
    }
  },
  computed: {
    // Function to get the date in YYYY-MM-DD format
    minDate() {
      // Get today's date
      const today = new Date();
      // Extract year from today's date
      const year = today.getFullYear();
      // Extract month from today's date and ensure it's in two-digit format
      const month = String(today.getMonth() + 1).padStart(2, '0');
      // Extract day from today's date and ensure it's in two-digit format
      const day = String(today.getDate()).padStart(2, '0');
      // Return the minimum date in YYYY-MM-DD format
      return `${year}-${month}-${day}`;
    },
    disableIfNoDuration() {
      return !this.meeting_duration;
    },
  },
};
