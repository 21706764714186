<template>
  <div class="notification_fail notification">
    <div class="flex justify-center items-center flex-col gap-2">
      <div class="identifier">
        <svg class="w-7" viewBox="0 0 117 117" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <title></title>
            <desc></desc>
            <defs></defs>
            <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
              <g fill-rule="nonzero" id="cancel">
                <path
                  d="M58.5,116.6 C90.5,116.6 116.6,90.6 116.6,58.5 C116.6,26.4 90.5,0.4 58.5,0.4 C26.5,0.4 0.4,26.5 0.4,58.5 C0.4,90.5 26.5,116.6 58.5,116.6 Z M58.5,8.6 C86,8.6 108.4,31 108.4,58.5 C108.4,86 86,108.4 58.5,108.4 C31,108.4 8.6,86 8.6,58.5 C8.6,31 31,8.6 58.5,8.6 Z"
                  fill="#ED3126" id="Shape"></path>
                <path
                  d="M36.7,79.7 C37.5,80.5 38.5,80.9 39.6,80.9 C40.7,80.9 41.7,80.5 42.5,79.7 L58.5,63.7 L74.5,79.7 C75.3,80.5 76.3,80.9 77.4,80.9 C78.5,80.9 79.5,80.5 80.3,79.7 C81.9,78.1 81.9,75.5 80.3,73.9 L64.3,57.9 L80.3,41.9 C81.9,40.3 81.9,37.7 80.3,36.1 C78.7,34.5 76.1,34.5 74.5,36.1 L58.5,52.1 L42.5,36.1 C40.9,34.5 38.3,34.5 36.7,36.1 C35.1,37.7 35.1,40.3 36.7,41.9 L52.7,57.9 L36.7,73.9 C35.1,75.5 35.1,78.1 36.7,79.7 Z"
                  fill="#ED3126" id="Shape"></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class=" text-[#ED3126] font-extrabold">يرجى تعبئة جميع الحقول</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.notification {
  animation: notify 3s ease-in-out 1;
}

@keyframes notify {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  10% {
    transform: translate(-50%, -50%) scale(1);
  }

  13% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  16% {
    transform: translate(-50%, -50%) scale(1);
  }

  55% {
    transform: translate(-50%, -50%) scale(1);
  }

  65% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  95% {
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>