<template>
  <div class="flex relative">
    <div class="left-0 absolute w-full  px-12">
      <headerComponent></headerComponent>

      <div class="text-center mt-36">
        <svg class="w-20 mx-auto" fill="#f2af72" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <g fill="#f2af72">
              <path
                d="M8 0a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-8-8zm0 1a7 7 0 0 1 7 7 7 7 0 0 1-7 7 7 7 0 0 1-7-7 7 7 0 0 1 7-7z">
              </path>
              <path
                d="M8.708 5.663q0 1.19-.085 2.167-.085.963-.212 1.926h-.822q-.127-.963-.212-1.926-.085-.977-.085-2.167V3h1.416zm.241 6.388q0 .382-.255.666Q8.44 13 8 13q-.44 0-.694-.283-.255-.284-.255-.666 0-.382.255-.666.255-.283.694-.283.44 0 .694.283.255.284.255.666z"
                style="line-height:1000%;-inkscape-font-specification:Ubuntu" font-weight="400" font-family="Ubuntu"
                letter-spacing="0" word-spacing="0"></path>
            </g>
          </g>
        </svg>
        <div class="w-[333px] mx-auto mt-6">
          <p>يجب عليك تسجيل الدخول أولا للنظام من هنا إن لم يكن لديك حساب يرجى التواصل مع الإدارة للتسجيل
            info@quickstep.sa</p>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import headerComponent from '../components/headerSection.vue'
export default {
  components: {
    headerComponent
  },
}
</script>

<style lang="scss" scoped></style>